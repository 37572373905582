import classNames from "classnames";
import Button from "components/Button";
import Loader from "components/Loader";
import NumbersKeypad from "components/NumbersKeypad";
import TextFeild from "components/TextFeild";
import Typography from "components/Typography";
import { AppEventTypes, CanvasMessage, matchStatusEnums } from "core/enums";
import { OddsDisplayEnum, PlaceBetMutation, useCurrentBetSlipLazyQuery, usePlaceBetMutation } from "generated/graphql";
import React, { useCallback, useRef, useState } from "react";
import sessionHelper from "service/sessionHelper";
import useGameStore from "store/gameStore";
import styles from "styles/components/stakePannelInput.module.css";
import getConfig from "util/configHelper";
import appEventInstance from "util/eventHandler";
import { debounce, debug, extractCurrencySymbol, setNumberFormat, timer } from "util/miscUtils";
import { shallow } from "zustand/shallow";
import Vault from "../../../components/Vault";
import useHandleAddUpdateOdds from "../../../hooks/useHandleAddUpdateOdds";

interface StakePannelInputProps {
  updateBetPannelOptions?: (data: Record<string, any>) => void; // Update function signature to accept an object
  _transferInfoToPixi: (data: { type: string; value: any }) => void;
}

const StakePannelInput: React.FC<StakePannelInputProps> = ({ _transferInfoToPixi }) => {
  const { errConfigData } = getConfig();

  const {
    udpateModalStates,
    selectedOddsOptions,
    updateStakeById,
    setSelectedOddsOptions,
    activeOddsOptions,
    setActiveOddsOptions,
    updateDrawerStates,
    selectedOddsDisplay,
    setBetPlaceData,
    setMatchStatus,
  } = useGameStore(
    (state) => ({
      udpateModalStates: state.updateModalStates,
      matchStatus: state.matchStatus,
      setMatchStatus: state.setMatchStatus,
      updateDrawerStates: state.updateDrawerStates,
      selectedOddsOptions: state.selectedOddsOptions,
      activeOddsOptions: state.activeOddsOptions,
      setActiveOddsOptions: state.setActiveOddsOptions,
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      updateStakeById: state.updateStakeById,
      isOpenStakeInfoModal: state.modalStates.isOpenStakeInfoModal,
      selectedOddsDisplay: state.selectedOddsDisplay,
      setBetPlaceData: state.setBetPlaceData,
    }),
    shallow,
  );
  const [playAnimation, setPlayAnimation] = useState(sessionHelper?.content.selectedOddsInputsView);
  const [showKeypad, setShowKeyPad] = useState(false);
  const [isBetPlaced, setIsBetPlaced] = useState(false);
  const [disablePlaceBetOnKeyClick, setDisablePlaceBetOnKeyClick] = useState(false); // disable click on placeBet until stake is being update in the api call ....
  const [disablePlaceBetOnBetPlace, setDisablePlaceBetOnBetPlace] = useState(false); // disable click on placeBet until stake is being update in the api call ....
  const [betPannelBooleanValues, setBetPannelBooleanValues] = useState({
    isBetPlaced: false,
    showKeypad: false,
    disablePlaceBetOnKeyClick: false,
    disablePlaceBetOnBetPlace: false,
  });
  const [activeInputId, setActiveInputId] = useState({ key: "", oddId: "", betId: "" });
  const [defaultKeyOrNumbersKey, setDefaultKeyOrNumbersKey] = useState("keypadKeys");
  let _singleOddReturnVal = useRef<string | undefined>();

  const { handleBoxClick, isAddingBetLoading, error } = useHandleAddUpdateOdds();
  debug({ event: "calling", message: "Stake Pannel inputs " });

  const [currentStakeSlipsData, { data, error: isCurrentStakeSlipError, loading: isCurrentStakeSlipsLoading }] =
    useCurrentBetSlipLazyQuery();

  const debouncedApiCall = useCallback(
    debounce(async (oddId: string, betId: string, stake: number) => {
      // console.log("debounced stake : ", { oddId, betId, stake });
      let updateStakeSlip = await handleBoxClick(oddId, sessionHelper?.content.updateStakeSlip, [], betId, stake);
      // setDisablePlaceBetOnKeyClick(false);
      setBetPannelBooleanValues((prev) => ({ ...prev, disablePlaceBetOnKeyClick: false }));
    }, 500), // Delay
    [],
  );

  const listRef = useRef<HTMLDivElement | null>(null);

  const handleScrollToTop = () => {
    if (listRef.current) {
      listRef.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const handleKeyClick = async (key: string | number) => {
    setDefaultKeyOrNumbersKey("keypadKeys");
    const activeOption = selectedOddsOptions[activeInputId?.key]?.find(
      (option) => option.oddId === activeInputId.oddId,
    );

    if (!activeOption) return; // Exit if no matching option is found

    // setDisablePlaceBetOnKeyClick(true);
    setBetPannelBooleanValues((prev) => {
      return { ...prev, disablePlaceBetOnKeyClick: true };
    });

    // Raw stake value (unformatted)
    let stakeString = activeOption.betAmount?.toString() || "";

    if (key === "icon") {
      // Handle backspace: Remove the last character
      stakeString = stakeString.slice(0, -1);
      if (stakeString === "" || stakeString === "0") {
        stakeString = ""; // Remove "0" and keep empty
      }
      // setDisablePlaceBetOnKeyClick(false);
      setBetPannelBooleanValues((prev) => {
        return { ...prev, disablePlaceBetOnKeyClick: false };
      });

      updateStakeById(activeInputId.key, activeInputId.oddId, stakeString);
      debouncedApiCall(activeInputId.oddId, activeInputId.betId, Number(stakeString || 0));
      return;
    }

    if (key === "decimal") {
      // Handle decimal input: Add if it doesn't already exist
      if (!stakeString.includes(".") && stakeString.length > 0) {
        stakeString += ".";
      }
      if (Number(stakeString) > 9999) {
        return;
      }
      updateStakeById(activeInputId.key, activeInputId.oddId, stakeString);
      // setDisablePlaceBetOnKeyClick(false);
      setBetPannelBooleanValues((prev) => {
        return { ...prev, disablePlaceBetOnKeyClick: false };
      });
      return;
    }

    // Validate: If already two decimal places exist, reject input
    if (stakeString.includes(".")) {
      const [integerPart, decimalPart] = stakeString.split(".");
      if (decimalPart?.length >= 2) {
        // setDisablePlaceBetOnKeyClick(false);
        setBetPannelBooleanValues((prev) => {
          return { ...prev, disablePlaceBetOnKeyClick: false };
        });
        return; // Reject input if decimal already has two digits
      }
    }

    // Append numeric keys (0-9) or handle replacement of "0"
    if (stakeString === "0" && key !== "decimal") {
      stakeString = ""; // Replace "0" with new input
    }
    stakeString += key;

    // Validate: Enforce maximum value of 10,000
    const numericValue = parseFloat(stakeString);
    if (numericValue > 10000) {
      stakeString = "10000"; // Cap to max value
      udpateModalStates({ isOpenMinMaxStakeModal: true });
    }

    if (stakeString.length === 3) {
      let [integerPart, decimalPart] = stakeString.split(".");
      if (decimalPart === "0") {
        if (stakeString < "0.10") {
          udpateModalStates({ isOpenMinMaxStakeModal: true });
          stakeString = "0.10";
        }
      }
    }

    // Check for max payout logic
    if (calculateTotalReturnValue() >= sessionHelper?.maxPayoutReturn) {
      udpateModalStates({ isOpenMinMaxStakeModal: true });
    }

    // Update state and API calls
    updateStakeById(activeInputId.key, activeInputId.oddId, stakeString);
    debouncedApiCall(activeInputId.oddId, activeInputId.betId, parseFloat(stakeString));
  };

  const handleOptionClick = (defaultKey: string | number) => {
    setDefaultKeyOrNumbersKey("defaultKey");
    // setDisablePlaceBetOnKeyClick(true);
    setBetPannelBooleanValues((prev) => {
      return { ...prev, disablePlaceBetOnKeyClick: true };
    });
    updateStakeById(activeInputId.key, activeInputId.oddId, defaultKey as string);
    debouncedApiCall(activeInputId.oddId, activeInputId.betId, Number(defaultKey));
  };

  const handleInputChange = ({ value, name }: { value: string; name: string }) => {
    // console.log("Input Name:", name, value); // Access the input name as needed
  };

  const [betsMutation, { data: placeBetData, loading: isPlaceBetLoading, error: isPlaceBetError }] =
    usePlaceBetMutation({
      variables: {},
    });

  // handling both start and place bet functionality here
  const handlePlaceBet = async () => {
    // setShowKeyPad(false);
    setBetPannelBooleanValues((prev) => {
      return { ...prev, showKeypad: false };
    });

    if (betPannelBooleanValues.isBetPlaced) {
      console.log("In Start Match ........");
      // if bet place is true then just start match........
      updateDrawerStates({ isOpenStakePannelOptions: false });
      //@ts-ignore
      await _transferInfoToPixi?.({
        type: CanvasMessage.startSimulation,
        value: {},
      });
      await timer(1000); // reset all the state after one min...
      setMatchStatus(matchStatusEnums.matchStarted);
      setSelectedOddsOptions([], "removeAllData");
      setMatchStatus(matchStatusEnums.selectingOdds);
      sessionHelper.updateOddDetails([] as any);
      // setIsBetPlaced(false);
      setBetPannelBooleanValues((prev) => {
        return { ...prev, isBetPlaced: false };
      });
    } else {
      // setDisablePlaceBetOnBetPlace(true);
      setBetPannelBooleanValues((prev) => {
        return { ...prev, disablePlaceBetOnBetPlace: true };
      });
      // console.log("In Place Bet ........", betPannelBooleanValues.isBetPlaced, { isPlaceBetLoading });
      // console.log(calculateTotalBetAmount(), sessionHelper?.balance);
      if (calculateTotalBetAmount() > Number(sessionHelper?.balance)) {
        appEventInstance.dispatch({
          eventType: AppEventTypes.toggleErrorModal,
          payload: { msg: errConfigData.Stake01 },
        });
        return;
      } else {
        try {
          let placeBet = await betsMutation();
          let placeBetResp = placeBet?.data?.players?.placeBet?.bets;
          setBetPlaceData(placeBet?.data); // setkting betplace response ....
          console.log({ placeBetResp });
          //@ts-ignore
          await _transferInfoToPixi?.({
            type: CanvasMessage.betPlaceResponse,
            value: placeBet?.data?.players?.placeBet,
          });
          if (placeBetResp?.length) {
            let resp = await currentStakeSlipsData();
            let totalCurrentStakeSlipAval = resp?.data?.players?.currentBetSlip;
            // setIsBetPlaced(true);
            setBetPannelBooleanValues((prev) => {
              return { ...prev, isBetPlaced: true };
            });
            const iframeRef = useGameStore.getState().iframeRef;
            if (
              iframeRef &&
              //@ts-ignore
              iframeRef?.contentWindow &&
              //@ts-ignore
              iframeRef?.contentWindow?.gameExtension
            ) {
              //@ts-ignore
              iframeRef?.contentWindow?.gameExtension({
                type: CanvasMessage?.sendVaultData,
                value: {},
              });
            }
            handleScrollToTop();
            setActiveOddsOptions(totalCurrentStakeSlipAval as any); // store all the  reponse except added_to_slip
            setPlayAnimation(sessionHelper?.content.betPlaceView);
            setMatchStatus(matchStatusEnums.betPlaced);
            // setDisablePlaceBetOnBetPlace(false);
            setBetPannelBooleanValues((prev) => {
              return { ...prev, disablePlaceBetOnBetPlace: false };
            });
          } else {
            appEventInstance.dispatch({
              eventType: AppEventTypes.toggleErrorModal,
              payload: { msg: errConfigData.Stake00 },
            });
          }
        } catch (err) {
          //@ts-ignore
          if (err?.message === errConfigData.GEN01) {
            appEventInstance.dispatch({
              eventType: AppEventTypes.toggleErrorModal,
              payload: { msg: errConfigData.GEN01 },
            });
          } else {
            appEventInstance.dispatch({
              eventType: AppEventTypes.toggleErrorModal,
              payload: { msg: errConfigData.Stake00 },
            });
          }
        }
      }
    }
  };

  const handleAddMoreOddsSlips = async () => {
    // setIsBetPlaced(false);
    setBetPannelBooleanValues((prev) => {
      return { ...prev, isBetPlaced: false };
    });
    setMatchStatus(matchStatusEnums.selectingOdds);
    setPlayAnimation(sessionHelper?.content.selectedOddsInputsView);
    try {
      let resp = await currentStakeSlipsData();
      let totalCurrentStakeSlipAval = resp?.data?.players?.currentBetSlip;
      setSelectedOddsOptions(totalCurrentStakeSlipAval as any, "reset"); // store only added_to_slip
    } catch (err) {
      console.error(error);
    }
  };

  // to show and undo the place bet btn and XP showing ...
  const checkIsAnyStakeUpdated = () => {
    for (let key in selectedOddsOptions) {
      if (betPannelBooleanValues.isBetPlaced) {
        return true;
      }
      if (selectedOddsOptions[key].some((item) => item.betAmount && Number(item.betAmount) >= 0.1)) {
        return true; // Return true if any betAmount is greater than threshold
      }
    }
    return false;
  };

  const handleUserInteraction = async (id: string, marketName: string) => {
    try {
      if (
        marketName === sessionHelper?.content.firstGoalScorer ||
        marketName === sessionHelper?.content.lastGoalScorer ||
        marketName === sessionHelper?.content.AnyTimeGoalScorer
      ) {
        marketName = sessionHelper?.content.firstLastAnytimeGoalScorer;
      }
      let resp = await handleBoxClick(id, marketName, [...selectedOddsOptions[marketName]]);
      setSelectedOddsOptions(resp || [], marketName);
    } catch (e) {}
  };

  const calculateTotalBetAmount = () => {
    // Use reduce to sum the betAmount values from each array under the keys
    let _totalBetAmt = Object.values(selectedOddsOptions).reduce((sum, arr) => {
      return sum + arr.reduce((innerSum, item) => innerSum + Number(item.betAmount), 0);
    }, 0);
    return _totalBetAmt;
  };

  const calculateTotalReturnValue = () => {
    return Object.values(selectedOddsOptions).reduce((sum, arr) => {
      return sum + arr.reduce((innerSum, item) => innerSum + Number(item.betAmount) * item.decimalOdd, 0);
    }, 0);
  };

  const getTeamName = (name: string) => {
    if (name === "A") return sessionHelper.oddsDetails?.teamA?.name || "-";
    if (name === "B") return sessionHelper.oddsDetails?.teamB?.name || "-";
    return "Draw";
  };

  const getBoostPercentage = (decimalOdd: number) => {
    if (decimalOdd > 11) return 100;
    if (decimalOdd > 6) return 40;
    if (decimalOdd > 3) return 20;
    return 0;
  };

  // functionality for which stake should take the numbers key values
  const handleSetActiveInputId = (oddId: string, marketName: string, betId: string) => {
    // setShowKeyPad(true);
    setBetPannelBooleanValues((prev) => {
      return { ...prev, showKeypad: true };
    });
    appEventInstance.dispatch({
      eventType: AppEventTypes.ToggleDrawerScroll,
      payload: { isScrollDisabled: true },
    });
    // set market name as box heding rather than setting first , last , anyTime Goal scorer for activating the bet input which is active with bet id ...
    if (
      marketName === sessionHelper?.content.firstGoalScorer ||
      marketName === sessionHelper?.content.lastGoalScorer ||
      marketName === sessionHelper?.content.AnyTimeGoalScorer
    ) {
      marketName = sessionHelper?.content.firstLastAnytimeGoalScorer;
    }
    if (oddId) {
      setActiveInputId({ oddId, key: marketName, betId });
    }
  };

  const getPlayerName = (playerId: number | string) => {
    let allPlayers = sessionHelper.oddsDetails?.allPlayers;
    let playerDetails = allPlayers?.filter((ele, index) => ele.playerId === playerId);
    if (playerDetails?.length) {
      return playerDetails[0].name;
    } else {
      return "player not found.";
    }
  };

  let renderedCount = 0;
  let SelectedOddsOptionsCount = Object.values(selectedOddsOptions).reduce((sum, arr) => sum + arr.length, 0);

  const _displayOddType = (item: any) => {
    switch (selectedOddsDisplay) {
      case OddsDisplayEnum.Fractional:
        return item.fractionalOdd || "F";
      case OddsDisplayEnum.Decimal:
        return item.decimalOdd || "D";
      case OddsDisplayEnum.American:
        return item.americanOdd || "DD";
      default:
        return "notFound - ";
    }
  };
  return (
    <section
      className={classNames(styles.selectedStakeOptionsRoot, {
        [styles.visibility]: SelectedOddsOptionsCount === 0 ? true : false,
      })}
    >
      <div
        className={classNames(styles.animationRoot, {
          [styles.addPaddingtop]: !betPannelBooleanValues.isBetPlaced,
          [styles.addPaddingBottomg]: !betPannelBooleanValues.showKeypad,
          [styles.selectedOddsInputsView]: playAnimation === sessionHelper?.content.selectedOddsInputsView,
          [styles.selectedOddsInputsAndKeypadView]: betPannelBooleanValues.showKeypad,
          [styles.selectedOddsLabelsView]: playAnimation === sessionHelper?.content.selectedOddsLabelsView,
          [styles.betPlaceView]: playAnimation === sessionHelper?.content.betPlaceView,
        })}
      >
        <Vault hide={!betPannelBooleanValues.isBetPlaced} />
        {/* bet place animation bar  */}
        <div
          className={classNames(styles.betPlaceBar, {
            [styles.hide]: !betPannelBooleanValues.isBetPlaced,
            [styles.slideFromLeft]: true,
          })}
        >
          <img src="/images/bets/active.png" alt="active btn" />
          <div className={styles.flexDirectionCol}>
            <Typography fontFamily="avenirNextSemiBold" size="lg">
              {sessionHelper?.content.betPlaced}
            </Typography>
          </div>
        </div>

        {/* selected options lables counter with market names*/}
        <div
          className={classNames(styles.selectedStakeContainer, {
            [styles.hide]: playAnimation !== sessionHelper?.content.selectedOddsLabelsView,
          })}
        >
          <div className={classNames(styles.counterContainer)}>
            <div className={classNames(styles.counter)}>
              <Typography size={SelectedOddsOptionsCount > 99 ? "xs2" : "md"} fontFamily="avenirNextBold" color="green">
                {SelectedOddsOptionsCount}
              </Typography>
            </div>
            <Typography size="md" fontFamily="avenirNextBold" color="black">
              {SelectedOddsOptionsCount > 1 ? sessionHelper?.content.selections : sessionHelper?.content.selection}
            </Typography>
          </div>
          <div className={classNames(styles.teamListContainer)}>
            {Object.entries(selectedOddsOptions).map(([key, values], itemsIndex) => {
              const filteredValues = values.filter((value) => value?.betStatus === sessionHelper?.content.addedToSlip);

              // Calculate how many items can still be rendered
              const remainingSlots = 3 - renderedCount;

              // If no slots are remaining, skip rendering and return "..."
              if (remainingSlots <= 0) {
                return null;
              }

              // Slice the filtered values to ensure only the remaining slots are considered
              const valuesToRender = filteredValues.slice(0, remainingSlots);

              renderedCount += valuesToRender.length; // Update the rendered count

              // Determine if the total length exceeds 3 to show "..."
              const isMoreThanThree =
                Object.values(selectedOddsOptions).flatMap((val) =>
                  val.filter((v) => v?.betStatus === sessionHelper?.content.addedToSlip),
                ).length > 3;

              return (
                <React.Fragment key={`${key}-${itemsIndex}`}>
                  {valuesToRender.map((value) => (
                    <div key={`${value?.marketName}-${value?.betId}`} className={classNames(styles.selectedTeamList)}>
                      <Typography
                        hide={value.marketName !== sessionHelper?.content.finalResult}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getTeamName(value.oddsDetails.win as string)}
                      </Typography>
                      <Typography
                        hide={value.marketName !== sessionHelper?.content.goalsOverUnder}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {value.oddsDetails.type &&
                          value.oddsDetails.type.charAt(0).toUpperCase() + value.oddsDetails.type.slice(1)}{" "}
                        {value.oddsDetails.goals}
                      </Typography>
                      <Typography
                        hide={value.marketName !== sessionHelper?.content.bothTeamsToScore}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {value.oddsDetails.value}
                      </Typography>

                      <Typography
                        hide={value.marketName !== sessionHelper?.content.resultBothTeamsScore}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getTeamName(value.oddsDetails.win as string)} / {value.oddsDetails.value}
                      </Typography>

                      <div className={classNames(styles.selectedMarketsLabels)}>
                        <Typography
                          hide={value.marketName !== sessionHelper?.content.correctScoree}
                          color="black"
                          fontFamily="avenirNextBold"
                        >
                          {`${value.oddsDetails.team1} - ${value.oddsDetails.team2}`}
                        </Typography>
                        <Typography
                          hide={value.marketName !== sessionHelper?.content.correctScoree}
                          fontFamily="avenirFont"
                          color="black"
                          size="md2"
                        >
                          {getTeamName(value.oddsDetails.win as string)}
                        </Typography>
                      </div>

                      <div className={classNames(styles.selectedMarketsLabels)}>
                        <Typography
                          hide={value.marketType !== sessionHelper?.content.firstLastAnytimeGoalScorerType}
                          color="black"
                          fontFamily="avenirNextBold"
                        >
                          {getPlayerName(value.oddsDetails.playerId)}
                        </Typography>
                        <Typography
                          hide={value.marketType !== sessionHelper?.content.firstLastAnytimeGoalScorerType}
                          fontFamily="avenirFont"
                          color="black"
                          size="md2"
                        >
                          {getTeamName(value.oddsDetails.teamId as string)}
                        </Typography>
                      </div>

                      <Typography
                        hide={value.marketName !== sessionHelper?.content.handicaps}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getTeamName(value.oddsDetails.win as string)} {value.oddsDetails.handicap}
                      </Typography>

                      <Typography
                        hide={value.marketName !== sessionHelper?.content.firstGoalScorerCorrectScore}
                        color="black"
                        fontFamily="avenirNextBold"
                      >
                        {getPlayerName(value.oddsDetails.playerId)} /{" "}
                        {`${value.oddsDetails.team1} - ${value.oddsDetails.team2}`}
                      </Typography>
                    </div>
                  ))}
                  {isMoreThanThree && renderedCount >= 3 && (
                    <Typography color="gray" fontFamily="degularBold" size="xl" className={styles.moreText}>
                      ...
                    </Typography>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        {/* arrow down  */}
        <div
          className={classNames(styles.arrow, {
            [styles.hide]: betPannelBooleanValues.isBetPlaced,
            [styles.centerFlatArrowDown]: playAnimation === sessionHelper?.content.selectedOddsLabelsView,
          })}
        >
          <img
            src="icon/flatArrowDown.png"
            alt="flatArrowDown"
            onClick={() => {
              if (betPannelBooleanValues.showKeypad) {
                // setShowKeyPad(!betPannelBooleanValues.showKeypad);
                setBetPannelBooleanValues((prev) => {
                  return { ...prev, showKeypad: !prev.showKeypad };
                });
              }
              if (playAnimation === sessionHelper?.content.selectedOddsInputsView) {
                setPlayAnimation(sessionHelper?.content.selectedOddsLabelsView);
              } else {
                setPlayAnimation(sessionHelper?.content.selectedOddsInputsView);
              }
            }}
          />
        </div>
        {/* arrow down  end*/}

        <div
          className={classNames(styles.row, {
            [styles.hide]: playAnimation === sessionHelper?.content.selectedOddsLabelsView,
          })}
        >
          <section ref={listRef} className={classNames(styles.stakeInputsContainer)}>
            {Object.entries(betPannelBooleanValues.isBetPlaced ? activeOddsOptions : selectedOddsOptions).map(
              ([key, values], oddsIndex) => (
                <React.Fragment key={key}>
                  {values.map((item, index) => {
                    if (SelectedOddsOptionsCount === 1) {
                      _singleOddReturnVal.current = setNumberFormat(
                        (item.betAmount && item.decimalOdd * Number(item.betAmount))?.toLocaleString() ?? 0,
                        2,
                      );
                    }
                    return (
                      <div key={`${item.betId}-${index}`} className={classNames(styles.stakeInputsList)}>
                        <div
                          className={classNames(styles.col, {
                            [styles.addPaddingtop]: betPannelBooleanValues.isBetPlaced,
                          })}
                        >
                          <Typography
                            color="purpleBlue700"
                            size={item.marketName.length > 25 ? "xs" : item.marketName.length > 20 ? "md" : "md2"}
                            fontFamily="avenirNextMedium"
                          >
                            {item.marketName}
                          </Typography>
                        </div>
                        {/* selected market list below with stake pannel inputs ..... */}
                        <div className={classNames(styles.col)}>
                          <div>
                            <div className={classNames(styles.flexSubCol, styles.addGap4)}>
                              <div
                                className={classNames(styles.marketDetails, {
                                  [styles.hide]: item.marketName !== sessionHelper?.content.finalResult,
                                })}
                              >
                                {" "}
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {getTeamName(item.oddsDetails.win as string)}
                                </Typography>
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {_displayOddType(item as any)}
                                </Typography>
                              </div>

                              {/* ================= */}
                              <div
                                className={classNames(styles.marketDetails, {
                                  [styles.hide]: item.marketName !== sessionHelper?.content.goalsOverUnder,
                                })}
                              >
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {item.oddsDetails.type &&
                                    item.oddsDetails.type.charAt(0).toUpperCase() + item.oddsDetails.type.slice(1)}
                                </Typography>
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {item.oddsDetails.goals}
                                </Typography>{" "}
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {_displayOddType(item as any)}
                                </Typography>
                              </div>

                              {/* ================= */}

                              <div
                                className={classNames(styles.marketDetails, {
                                  [styles.hide]: item.marketName !== sessionHelper?.content.bothTeamsToScore,
                                })}
                              >
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {item.oddsDetails.value}
                                </Typography>{" "}
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {_displayOddType(item as any)}
                                </Typography>
                              </div>

                              {/* ================= */}

                              <div
                                className={classNames(styles.marketDetails, {
                                  [styles.hide]: item.marketName !== sessionHelper?.content.resultBothTeamsScore,
                                })}
                              >
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md2">
                                  {getTeamName(item.oddsDetails.win as string)} / {item.oddsDetails.value}
                                </Typography>
                                <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                  {_displayOddType(item as any)}
                                </Typography>
                              </div>

                              {/* ================= */}

                              <div
                                className={classNames(styles.marketDetails, {
                                  [styles.hide]: item.marketName !== sessionHelper?.content.correctScoree,
                                  [styles.flexColDirection]: true,
                                })}
                              >
                                <div>
                                  {" "}
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {item.oddsDetails.team1} - {item.oddsDetails.team2}
                                  </Typography>
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md2">
                                    {_displayOddType(item as any)}
                                  </Typography>
                                </div>
                                <Typography color="purpleBlue" fontFamily="avenirFont" size="md2">
                                  {getTeamName(item.oddsDetails.win as string)}
                                </Typography>
                              </div>

                              {/* ================= */}

                              <div
                                className={classNames(styles.marketDetails, {
                                  [styles.hide]:
                                    item.marketType !== sessionHelper?.content.firstLastAnytimeGoalScorerType,
                                  [styles.flexColDirection]: true,
                                })}
                              >
                                <div>
                                  {" "}
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {getPlayerName(item.oddsDetails.playerId)}
                                  </Typography>
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {_displayOddType(item as any)}
                                  </Typography>
                                </div>
                                <Typography color="purpleBlue" fontFamily="avenirFont" size="md2">
                                  {getTeamName(item.oddsDetails.teamId as string)}
                                </Typography>
                              </div>

                              {/* ================= */}

                              <div
                                className={classNames(styles.marketDetails, {
                                  [styles.hide]: item.marketName !== sessionHelper?.content.handicaps,
                                  [styles.flexColDirection]: true,
                                })}
                              >
                                <div>
                                  {" "}
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {getTeamName(item.oddsDetails.win as string)}
                                  </Typography>{" "}
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {` ${item.oddsDetails.handicap}`}
                                  </Typography>
                                  <Typography color="purpleBlue" fontFamily="avenirNextBold" size="md">
                                    {_displayOddType(item as any)}
                                  </Typography>
                                </div>
                              </div>

                              {/* ================= */}

                              <div
                                className={classNames(styles.marketDetails, {
                                  [styles.hide]: item.marketName !== sessionHelper?.content.firstGoalScorerCorrectScore,
                                  [styles.flexColDirection]: true,
                                })}
                              >
                                <div>
                                  {" "}
                                  <Typography
                                    color="purpleBlue"
                                    fontFamily="avenirNextBold"
                                    size={getPlayerName(item.oddsDetails?.playerId).length > 10 ? "md2" : "md"}
                                    noWrap={true}
                                  >
                                    {getPlayerName(item.oddsDetails?.playerId)} /
                                    {`${item.oddsDetails.team1}-${item.oddsDetails.team2}`}
                                  </Typography>
                                  <Typography
                                    color="purpleBlue"
                                    fontFamily="avenirNextBold"
                                    size={item.odd.length > 4 ? "md2" : "md"}
                                  >
                                    {_displayOddType(item as any)}
                                  </Typography>
                                </div>
                                <Typography color="purpleBlue" fontFamily="avenirFont" size="md2">
                                  {getTeamName(item.oddsDetails.teamId as string)}
                                </Typography>
                              </div>
                            </div>
                            {/* change the versus when you get from BE */}
                            {/* <Typography hide={!item.boxSubLabel} color="purpleBlue" fontFamily="avenirNext" size="md"> */}
                            <Typography color="purpleBlue700" fontFamily="avenirNextMedium" size="xs2">
                              {sessionHelper?.oddsDetails?.teamA?.name} {sessionHelper?.content.v}
                              {sessionHelper?.oddsDetails?.teamB?.name}
                            </Typography>{" "}
                            <div
                              className={classNames(styles.flexSubCol, styles.addTopSpacing, {
                                [styles.visibility]: item.betAmount && Number(item?.betAmount) >= 0.1 ? false : true,
                              })}
                            >
                              <Typography noWrap={true} color="purple" fontFamily="avenirNextBold" size="xs1">
                                {/* update this once xpBoostPercentage comes in the current Bet Slip  */}
                                {getBoostPercentage(item?.decimalOdd) > 0 &&
                                  `${getBoostPercentage(item?.decimalOdd as any)}% ${sessionHelper?.content.vaultXpBoost}`}
                                {item.betAmount &&
                                sessionHelper?.oddsDetails?.xpToAward &&
                                getBoostPercentage(item?.decimalOdd as any) >= 20
                                  ? setNumberFormat(
                                      (
                                        item.betAmount * sessionHelper?.oddsDetails?.xpToAward +
                                        (item.betAmount *
                                          sessionHelper?.oddsDetails?.xpToAward *
                                          getBoostPercentage(item?.decimalOdd)) /
                                          100
                                      ).toLocaleString() ?? 0,
                                      0,
                                    )
                                  : item.betAmount &&
                                    sessionHelper?.oddsDetails?.xpToAward &&
                                    setNumberFormat(
                                      (item.betAmount * sessionHelper?.oddsDetails?.xpToAward).toLocaleString() ?? 0,
                                      0,
                                    )}
                                XP
                              </Typography>
                              <img
                                src="icon/info.png"
                                alt="flatArrowDown"
                                onClick={() => {
                                  udpateModalStates({ isOpenStakeInfoModal: true });
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className={classNames(styles.inputWidth, {
                              [styles.hide]: betPannelBooleanValues.isBetPlaced,
                            })}
                            onClick={() => handleSetActiveInputId(item.oddId, item.marketName, item.betId)}
                          >
                            <TextFeild
                              valueColor={item?.betAmount && item?.betAmount >= 0 ? "purpleBlue" : "purpleBlue500"}
                              isStakeInputHeight={true}
                              isLargeText={item?.betAmount ? item.betAmount > 4 : false}
                              startIcon={"currency"}
                              labelColor={"purpleBlue700"}
                              //@ts-ignore
                              borderColor={activeInputId.oddId === item?.oddId ? "purpleBlue" : "lightGray"}
                              label={sessionHelper?.content.stake}
                              //@ts-ignore
                              value={item?.betAmount === 0 ? item?.betAmount : item?.betAmount}
                              backGroundColor="lightGrayBg"
                              onChange={() => handleInputChange}
                              noDefaultKeypad={true}
                              placeholder="0.00"
                              actualBetAmount={item.betAmount as any}
                              defaultKeyOrNumberKey={defaultKeyOrNumbersKey}
                            />
                            <div>
                              <Typography
                                hide={
                                  item?.betAmount && Number(item.betAmount) > 0 && SelectedOddsOptionsCount !== 1
                                    ? false
                                    : true
                                }
                                color="purpleBlue700"
                                size={item?.betAmount && item?.betAmount > 10 ? "xs2" : "xs"}
                                fontFamily="avenirFont"
                                noWrap={true}
                              >
                                {sessionHelper?.content.toReturn} £
                                {setNumberFormat(
                                  (item.betAmount && item.decimalOdd * Number(item.betAmount))?.toLocaleString() ?? 0,
                                  2,
                                )}
                              </Typography>
                            </div>
                          </div>
                          <img
                            className={classNames(styles.closeIcon, {
                              [styles.hide]: betPannelBooleanValues.isBetPlaced,
                            })}
                            src="icon/grayClose.png"
                            alt="grayClose"
                            onClick={() => {
                              handleUserInteraction(item?.oddId, item.marketName);
                            }} // basically to check and remove the id Obj
                          />
                          <section
                            className={classNames(styles.stakeToReturn, {
                              [styles.hide]: !betPannelBooleanValues.isBetPlaced,
                            })}
                          >
                            <div className={classNames(styles.row)}>
                              <div>
                                <Typography color="purpleBlue700" size="md2" fontFamily="avenirNextMedium">
                                  {sessionHelper?.content.stake}
                                </Typography>
                                <Typography
                                  color="purpleBlue"
                                  size={setNumberFormat(item?.betAmount ?? 0, 2).length > 5 ? "md2" : "md"}
                                  fontFamily="avenirNextBold"
                                >
                                  {extractCurrencySymbol("£")}
                                  {setNumberFormat(item?.betAmount ?? 0, 2)}
                                </Typography>
                              </div>
                              <div>
                                <Typography color="purpleBlue700" size="md2" fontFamily="avenirNextMedium">
                                  {sessionHelper?.content.toReturn}
                                </Typography>
                                {/* change the calculation later  */}
                                <Typography
                                  color="purpleBlue"
                                  size={
                                    setNumberFormat(Number((item.betAmount as any) * item.decimalOdd) ?? 0, 2).length >
                                    5
                                      ? "md2"
                                      : "md"
                                  }
                                  fontFamily="avenirNextBold"
                                >
                                  {extractCurrencySymbol("£")}
                                  {item.betAmount &&
                                    setNumberFormat(Number((item.betAmount as any) * item.decimalOdd) ?? 0, 2)}
                                </Typography>
                              </div>
                            </div>

                            <div className={classNames(styles.betRef)}>
                              <Typography color="black" fontFamily="avenirNextMedium" size="xs2_5" noWrap={true}>
                                {sessionHelper?.content.betRef} {item.betId}
                              </Typography>
                            </div>
                          </section>
                        </div>
                      </div>
                    );
                  })}
                </React.Fragment>
              ),
            )}
          </section>
          <div
            className={classNames(styles.col, {
              [styles.highLightBox]: checkIsAnyStakeUpdated() && sessionHelper?.isFTU,
            })}
          >
            {/* remove this extraPadding props after clikng on stake input  */}
            <Button
              isShowClickTransition={true}
              addDropShadow={true}
              onClick={() => handlePlaceBet()}
              size="stretch"
              color={checkIsAnyStakeUpdated() ? "darkGreenBtn" : "purpleBlue100"}
              disabled={!checkIsAnyStakeUpdated()}
              addMinHeight={true}
              displayFlex={true}
              isDisableOnClick={
                isPlaceBetLoading ||
                betPannelBooleanValues.disablePlaceBetOnBetPlace ||
                betPannelBooleanValues.disablePlaceBetOnKeyClick
              } // disable until stake udpated from api
            >
              <div className={classNames({ [styles.hide]: betPannelBooleanValues.isBetPlaced })}>
                <div className={styles.placeBetRow}>
                  <Typography
                    hide={betPannelBooleanValues.isBetPlaced}
                    fontFamily="degularBold"
                    color="purpleBlue"
                    size="xl"
                  >
                    {sessionHelper?.content.placeBet}{" "}
                  </Typography>
                  {checkIsAnyStakeUpdated() && (
                    <div
                      className={classNames(styles.addLeftMargin, {
                        [styles.hide]: SelectedOddsOptionsCount === 1,
                      })}
                    >
                      <Typography
                        hide={betPannelBooleanValues.isBetPlaced}
                        fontFamily="degularBold"
                        color="purpleBlue"
                        size="xl"
                      >
                        {`${extractCurrencySymbol("£")}${setNumberFormat(calculateTotalBetAmount() ?? 0, 2)}`}
                      </Typography>
                    </div>
                  )}
                  <div
                    className={classNames(styles.conditionalLoading, {
                      [styles.activeLoader]: betPannelBooleanValues.disablePlaceBetOnKeyClick,
                    })}
                  >
                    <Typography size="xl" fontFamily="avenirNextBold" color="purpleBlue">
                      ...
                    </Typography>
                  </div>
                </div>
                <Typography
                  hide={!checkIsAnyStakeUpdated() || SelectedOddsOptionsCount > 1} // hide if more than two odds selected
                  fontFamily="avenirNextBold"
                  color="purpleBlue700"
                  size="xs"
                >
                  {sessionHelper?.content.toReturn}{" "}
                  {_singleOddReturnVal.current ? `${extractCurrencySymbol("£")}${_singleOddReturnVal.current}` : ""}
                </Typography>
              </div>

              <Typography
                hide={!betPannelBooleanValues.isBetPlaced}
                color={betPannelBooleanValues.isBetPlaced ? "purpleBlue" : "purpleBlue700"}
                fontFamily="degularBold"
                size={betPannelBooleanValues.isBetPlaced ? "xl" : "xs"}
              >
                {betPannelBooleanValues.isBetPlaced && sessionHelper?.content.startMatch}
              </Typography>
              <div className={styles.loaderContainer}>
                <Loader
                  type="grayLoader"
                  hide={betPannelBooleanValues.isBetPlaced ? betPannelBooleanValues.isBetPlaced : !isPlaceBetLoading}
                />
              </div>
            </Button>
          </div>
        </div>
        <div
          className={classNames(styles.numbersKeyPad, {
            [styles.showKeypadView]: betPannelBooleanValues.showKeypad,
            [styles.removeShowKeypadView]: !betPannelBooleanValues.showKeypad && activeInputId.oddId.length > 2,
          })}
        >
          <NumbersKeypad onClickOption={handleOptionClick} onClickKey={handleKeyClick} />
        </div>
      </div>

      <Button
        isShowClickTransition={true}
        onClick={() => handleAddMoreOddsSlips()}
        roundedEdge={true}
        addExtraPadding={true}
        displayFlex={true}
        size="stretch"
        color="blackBtn"
        hide={!betPannelBooleanValues.isBetPlaced}
      >
        <img src="icon/backArrow.png" alt="backButton" className={classNames(styles.backButtonImage)} />
        <Typography fontFamily="degularBold" size="2xl" transform={true}>
          {betPannelBooleanValues.isBetPlaced
            ? `${sessionHelper?.content.addMoreBets}`
            : `${sessionHelper?.content.back}`}
        </Typography>
      </Button>
    </section>
  );
};

export default StakePannelInput;
