import React from "react";
import SettingsDrawer from "components/drawers/SettingsDrawer";
import TeamEditDrawer from "components/drawers/TeamEditDrawer";
import StakePannelOptionsDrawer from "components/drawers/StakePannelOptionsDrawer";

const DrawerRenderer: React.FC = () => {
  return (
    <>
      <SettingsDrawer />
      <TeamEditDrawer />
      <StakePannelOptionsDrawer />
    </>
  );
};

export default DrawerRenderer;
