import React, { useRef, useEffect, useState, PropsWithChildren, HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";
import styles from "styles/components/drawer.module.css";
import Button from "components/Button";
import Typography from "components/Typography";
import Loader from "components/Loader";
import {
  DrawerPositions,
  ZIndexLevels,
  DrawerSizes,
  DrawerContentHeight,
  DrawerBgType,
  AppEventTypes,
} from "core/enums";
import appEventInstance from "util/eventHandler";

type DrawerProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    isOpen: boolean;
    onClose: () => void;
    header?: ReactNode;
    position?: DrawerPositions | null;
    showFooterButton?: boolean;
    hideButtonText?: boolean;
    buttonWithoutImg?: boolean;
    disabled?: boolean;
    roundedTop?: boolean;
    footerButtonText?: string;
    showNavigationBar?: boolean;
    drawerSize?: DrawerSizes;
    zIndexLevel?: ZIndexLevels;
    contentHeight?: DrawerContentHeight;
    drawerBgType?: DrawerBgType;
    loading?: boolean;
    disableSlideInAnimation?: boolean;
  }
>;

const Drawer: React.FC<DrawerProps> = ({
  id,
  isOpen,
  onClose,
  children,
  header,
  position = DrawerPositions.Top,
  showFooterButton = true,
  hideButtonText = false,
  buttonWithoutImg = false,
  disabled = false,
  footerButtonText = "Back",
  showNavigationBar = false,
  drawerSize = "", // default will take full height after header
  zIndexLevel,
  roundedTop = false,
  drawerBgType,
  contentHeight = DrawerContentHeight.Fit,
  loading = false,
  disableSlideInAnimation = false,
}) => {
  const drawerRef = useRef<HTMLDivElement | null>(null);
  const isRenderedRef = React.useRef({ value: false });
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const [top, setTop] = useState(74);
  const topRef = useRef({ value: 74 });

  const handleOnCloseDrawer = (drawerId: string | undefined) => {
    console.trace(id, drawerId);
    if (id && drawerId) {
      if (drawerId === id) {
        onClose();
        return;
      }
      let _modal = document.getElementById(id);

      setTimeout(() => {
        _modal?.classList.add(styles.hideEl);
      }, 500);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setHasBeenOpened(true);
    }

    if (isOpen && id && isRenderedRef.current.value) {
      let _modal = document.getElementById(id);

      setTimeout(() => {
        _modal?.classList.remove(styles.hideEl);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    isRenderedRef.current.value = true;

    appEventInstance.listen(AppEventTypes.toggleDrawerHeight, (data) => {
      if (topRef.current.value !== data?.detail?.top) {
        console.log("wrapper: drawer dimension changed");
        setTop(data?.detail?.top);
        topRef.current.value = data?.detail?.top;
      }
    });

    return () => appEventInstance.remove(AppEventTypes.toggleDrawerHeight);
  }, []);

  const drawerClass = classNames(
    styles.drawerContainer,
    styles.drawerPosition,
    { [styles.roundedTop]: roundedTop },
    { [styles.teamEditBg]: drawerBgType === DrawerBgType.TeamEdit },
    { [styles.teamNameBg]: drawerBgType === DrawerBgType.TeamName },
    // { "bg-ourTeam": drawerBgType === DrawerBgType.TeamName },
    { [styles.selectnationalityBg]: drawerBgType === DrawerBgType.SelectNationality },
    // { "bg-selectNationality": drawerBgType === DrawerBgType.SelectNationality },
    { [styles.summerPackBg]: drawerBgType === DrawerBgType.SummerPack },
    { [styles.betpannelOptionsBg]: drawerBgType === DrawerBgType.BetPannelOptions },
    {
      [styles.zIndexLevel1]: zIndexLevel === ZIndexLevels.Level1,
      [styles.zIndexLevel2]: zIndexLevel === ZIndexLevels.Level2,
    },
    {
      [styles.drawerTop]: position === DrawerPositions.Top,
      [styles.drawerBottom]: position === DrawerPositions.Bottom,
    },
    {
      [styles.hideEl]: !isRenderedRef.current.value || !hasBeenOpened,
      [styles.slideInTop]: isOpen && position === DrawerPositions.Top && !disableSlideInAnimation,
      [styles.slideOutTop]: !isOpen && hasBeenOpened && position === DrawerPositions.Top,
      [styles.slideInBottom]: isOpen && position === DrawerPositions.Bottom,
      [styles.slideOutBottom]: !isOpen && hasBeenOpened && position === DrawerPositions.Bottom,
      [styles.slideInLeft]: isOpen && position === DrawerPositions.Left,
      [styles.slideOutLeft]: !isOpen && hasBeenOpened && position === DrawerPositions.Left,
      [styles.slideInRight]: isOpen && position === DrawerPositions.Right,
      [styles.slideOutRight]: !isOpen && hasBeenOpened && position === DrawerPositions.Right,
    },
    {
      [styles.show]: isOpen,
      [styles.showNavigationBar]: isOpen && showNavigationBar,
      // [styles.showNavigationBarLg]: isOpen && showNavigationBar && drawerSize === "lg",
      // [styles.drawerPositionXl]: drawerSize === "xl",
      // [styles.drawerPositionLg]: drawerSize === "lg",
      // [styles.drawerPositionXs2]: drawerSize === "xs2",
      // [styles.drawerTopLg]: drawerSize === "lg" && position === DrawerPositions.Top,
      // [styles.drawerPositionXs]: drawerSize === "xs",
      // [styles.drawerTopXs]: drawerSize === "xs" && position === DrawerPositions.Top,
      // [styles.drawerTopXl]: drawerSize === "xl" && position === DrawerPositions.Top,
      [styles.backButton]: showFooterButton,
      [styles.backButtonHidden]: !showFooterButton,
      [styles.headerNodeHidden]: !header,
    },
  );

  return (
    <div id={id} ref={drawerRef} className={drawerClass} style={{ top: `${top}px`, height: `calc(100%-${top}px)` }}>
      <div className="w-full">{header}</div>
      <div
        className={classNames(styles.drawerContainerSub, {
          [styles.contentFull]: contentHeight === DrawerContentHeight.Full,
          [styles.contentScreen]: contentHeight === DrawerContentHeight.Screen,
        })}
      >
        {loading ? <Loader /> : children}
      </div>
      <Button
        disabled={disabled || hideButtonText}
        size="stretch"
        color="blackBtn"
        onClick={() => handleOnCloseDrawer(id)}
      >
        <img
          src="icon/backArrow.png"
          alt="backButton"
          className={classNames(styles.backButtonImage, {
            [styles.backButtonImgHidden]: buttonWithoutImg,
            [styles.hide]: footerButtonText !== "Back",
          })}
        />
        <Typography
          fontFamily="degularBold"
          size="xl"
          color={disabled ? "gray" : ""}
          transform={true}
          hidden={hideButtonText}
        >
          {footerButtonText}
        </Typography>
      </Button>
    </div>
  );
};

export default Drawer;
