import React from "react";
import Typography from "components/Typography";
import getConfig from "util/configHelper";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import Modal from "components/Modal";
import styles from "styles/components/modals/gameRulesModal.module.css";
import classNames from "classnames";
import { getConfigLabel, parseMessage } from "util/miscUtils";
import gameJson from "config/gameJSON.json";
import sessionHelper from "service/sessionHelper";

interface Column {
  id: string;
  label: React.ReactNode;
}

interface TableProps {
  columns: Column[];
  data: { [id: string]: React.ReactNode }[];
}

const Table: React.FC<TableProps> = ({ columns, data }) => {
  return (
    <div className={classNames(styles.tableRoot)}>
      <table>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.id}>
                <Typography fontFamily="avenirNextBold">{col.label}</Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col) => (
                <td key={col.id}>
                  <Typography fontFamily="avenirFont" size="xs">
                    {row[col.id]}
                  </Typography>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const preprocessColumns = (columns: Column[]) =>
  columns.map((col) => ({
    id: col.id,
    label: getConfigLabel(col.label as string),
  }));

const preprocessData = (data: any[]) =>
  data.map((row) => {
    const processedRow: { [id: string]: React.ReactNode } = {};
    Object.keys(row).forEach((key) => {
      processedRow[key] = getConfigLabel(row[key]);
    });
    return processedRow;
  });

const GameRulesModal = () => {
  const { configData } = getConfig();
  const { isOpenGameRulesModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenGameRulesModal: state.modalStates.isOpenGameRulesModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleClose = () => {
    updateModalStates({ isOpenGameRulesModal: false });
  };

  const rtp = ["£100,000", "£100,000"];

  return (
    <Modal
      id="gameRulesModal"
      open={isOpenGameRulesModal}
      handleClose={handleClose}
      title={sessionHelper?.content?.GameRulesTitle}
      addBorder={true}
      containerSize="xl"
      noPaddingTop={true}
    >
      <div className={classNames(styles.root)}>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.overview}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.overViewText1}</Typography>
          <Typography size="xs">{sessionHelper?.content?.overViewText2}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.playingMatch}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.playingMatchText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.betting}
          </Typography>
          {[0, 1, 2, 3, 4, 5].map((data, index) => {
            return (
              <div key={`${index}-${data}`}>
                <Typography size="xs">{`${sessionHelper?.content[`bettingText${index + 1}`]}`}</Typography>
              </div>
            );
          })}
          {/* <Typography size="xs">{sessionHelper?.content?.bettingText1}</Typography>
          <Typography size="xs">{sessionHelper?.content?.bettingText2}</Typography>
          <Typography size="xs">{sessionHelper?.content?.bettingText3}</Typography>
          <Typography size="xs">{sessionHelper?.content?.bettingText4}</Typography>
          <Typography size="xs">{sessionHelper?.content?.bettingText5}</Typography>
          <Typography size="xs">{sessionHelper?.content?.bettingText6}</Typography> */}
          <Typography size="xs">{sessionHelper?.content?.rtP}</Typography>
          <Table columns={preprocessColumns(gameJson?.rtp)} data={preprocessData(gameJson?.rtpData)} />
          <Typography size="xs">{sessionHelper?.content?.rtpValue1}</Typography>
          <Typography size="xs">{sessionHelper?.content?.rtpValue2}</Typography>
          <Typography size="xs">{sessionHelper?.content?.rtpValue3}</Typography>
          <Typography size="xs">{sessionHelper?.content?.rtpValue4}</Typography>
          <Typography size="xs">{sessionHelper?.content?.rtpValue5}</Typography>
          <Typography size="xs">{sessionHelper?.content?.rtpValue6}</Typography>
          <Typography size="xs">{parseMessage({ str: sessionHelper.content.rtpValue7, map: rtp })}</Typography>
          <Typography size="xs">{sessionHelper?.content?.rtpValue8}</Typography>
          <Table columns={preprocessColumns(gameJson?.multipliers)} data={preprocessData(gameJson?.multipliersData)} />
          <Typography size="xs">{sessionHelper?.content?.odds}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.pbfCoins}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.pbfCoinsText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.mechanics}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.mechanicsText1}</Typography>
          <Typography size="xs">{sessionHelper?.content?.mechanicsText2}</Typography>
          <Typography size="xs">• {sessionHelper?.content?.mechanicsText3}</Typography>
          <Typography size="xs">• {sessionHelper?.content?.mechanicsText4}</Typography>
          <Typography size="xs">• {sessionHelper?.content?.mechanicsText5}</Typography>
          <Typography size="xs">{sessionHelper?.content?.mechanicsText6}</Typography>
          <Typography size="xs">{sessionHelper?.content?.mechanicsText7}</Typography>
          <Typography size="xs">{sessionHelper?.content?.mechanicsText8}</Typography>
          <Typography size="xs">{sessionHelper?.content?.mechanicsText9}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.myTeam}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.myTeamText1}</Typography>
          <Typography size="xs">{sessionHelper?.content?.myTeamText2}</Typography>
          <Typography size="xs">• {sessionHelper?.content?.myTeamText3}</Typography>
          <Typography size="xs">• {sessionHelper?.content?.myTeamText4}</Typography>
          <Typography size="xs">• {sessionHelper?.content?.myTeamText5}</Typography>
          <Typography size="xs">• {sessionHelper?.content?.myTeamText6}</Typography>
          <Typography size="xs">• {sessionHelper?.content?.myTeamText7}</Typography>
          <Typography size="xs">• {sessionHelper?.content?.myTeamText8}</Typography>
          <Typography size="xs">{sessionHelper?.content?.myTeamText9}</Typography>
          <Table columns={preprocessColumns(gameJson?.chemistryScore)} data={gameJson?.chemistryScoreData} />
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.playerRating}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.playerRatingText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.playerPositionAndChem}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.playerPositionText}</Typography>
          <Table
            columns={preprocessColumns(gameJson?.playerPosition)}
            data={preprocessData(gameJson?.playerPositionData)}
          />
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.boosts}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.boostsText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.divisions}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.divisionsText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.dailyRewards}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.dailyRewardsText}</Typography>
        </div>
        <div className={classNames(styles.text)}>
          <Typography fontFamily="degularBold" size="xl">
            {sessionHelper?.content?.stores}
          </Typography>
          <Typography size="xs">{sessionHelper?.content?.storeText}</Typography>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(GameRulesModal);
