import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/finalResult.module.css";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import { _getTeamName, debug } from "util/miscUtils";
import sessionHelper from "service/sessionHelper";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import { OddsDisplayEnum } from "generated/graphql";
import { matchStatusEnums } from "core/enums";

const FinalResult = () => {
  const [openContent, setOpenContent] = useState(true);
  const [loadingIds, setLoadingIds] = useState(new Set()); // Tracks loading state by item ID

  const keyName = sessionHelper.oddsDetails?.finalResult?.name as string;
  const selectedFinalResultOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  const { setSelectedOddsOptions, updateStakeInputPannelState, selectedOddsDisplay, matchStatus } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      selectedOddsDisplay: state.selectedOddsDisplay,
      updateStakeInputPannelState: state.updateStakeInputPannelState,
      matchStatus: state.matchStatus,
    }),
    shallow,
  );

  const getTeamName = (name: string) => {
    if (name === "A") return sessionHelper.oddsDetails?.teamA.name || "-";
    if (name === "B") return sessionHelper.oddsDetails?.teamB.name || "-";
    return "Draw";
  };

  let finalResultOddDetails = sessionHelper.oddsDetails?.finalResult;

  const { handleBoxClick } = useHandleAddUpdateOdds();

  const handleUserInteraction = async (id: string, keyName: string) => {
    if (matchStatus === matchStatusEnums.betPlaced) return; // If the match is already placed, do nothing
    try {
      setLoadingIds((prev) => new Set(prev).add(id)); // Mark the specific box as loading
      let resp = await handleBoxClick(id, keyName, [...selectedFinalResultOptions]);
      setSelectedOddsOptions(resp || [], keyName);
      updateStakeInputPannelState({ isOpenStakeInputsPannel: true }); // Logic handling here
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingIds((prev) => {
        const updated = new Set(prev);
        updated.delete(id); // Remove loading state for the box
        return updated;
      });
    }
  };

  useEffect(() => {
    debug({ event: "calling", message: "final result inside useEffect" });
  }, []);

  return (
    <section className={classNames(styles.root)}>
      <Accordion
        open={openContent}
        boxHeading={finalResultOddDetails?.name || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
      >
        <div className={styles.boxList}>
          {["A", "draw", "B"].map((winType , index) => {
            const item = finalResultOddDetails?.data.find((item) => item.win === winType);
            if (!item) return null; // Skip if no matching data

            return (
              <section
                key={`${item.decimalOdd}-${item.odd}-${index}`}
                className={classNames(styles.list)}
              >
                <BoxSelection
                  active={
                    selectedFinalResultOptions.length &&
                    selectedFinalResultOptions?.some((ele) => ele.oddId === item.id)
                      ? true
                      : false
                  } // Determine active state
                  onClick={() => handleUserInteraction(item.id, keyName)}
                  title={getTeamName(item.win)}
                  value={
                    selectedOddsDisplay
                      ? selectedOddsDisplay === OddsDisplayEnum.Fractional
                        ? item.fractionalOdd.toString()
                        : selectedOddsDisplay === OddsDisplayEnum.Decimal
                          ? item.decimalOdd.toString()
                          : item.americanOdd.toString()
                      : ""
                  }
                  isClicked={loadingIds.has(item.id)} // Check if this box is in a loading state
                />
              </section>
            );
          })}
        </div>
      </Accordion>
    </section>
  );
};

export default React.memo(FinalResult);
