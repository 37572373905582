import { ReactElement } from "react";

import Home from "pages/home/Home";
import { RoutesEnum } from "core/enums";

interface RouteConfig {
  path: RoutesEnum;
  element: ReactElement;
}

export const routes: RouteConfig[] = [
  {
    path: RoutesEnum.Home,
    element: <Home />,
  },
];
