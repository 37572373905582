import { ReactNode, useState } from "react";
import classNames from "classnames";
import ContentShell from "components/ContentShell";
import BetSlip from "pages/bets/components/BetSlip";
import styles from "styles/pages/bets/bets.module.css";
import Typography from "components/Typography";
import { BetStatus, DateFilterEnum, useMyBetsQuery } from "generated/graphql";
import useGameStore from "store/gameStore";
import { loaderTypes } from "core/enums";
import gameJson from "config/gameJSON.json";
import { getConfigLabel } from "util/miscUtils";
import sessionHelper from "service/sessionHelper";

const Bets = () => {
  const [myBetsHistory, setMyBetsHistory] = useState({});
  const { setShowLoader, showLoader } = useGameStore((state) => ({
    setShowLoader: state.setShowLoader,
    showLoader: state.showLoader,
  }));
  const [betsFilters, setBetsFilter] = useState({
    betStatus: null,
    dateFilter: DateFilterEnum.Today,
    matchStatus: null,
    matchId: null,
    sortDir: null,
    sortKey: null,
    skipPagination: null,
    limit: 15,
    pageNo: 1,
  });
  const {
    data: myBetsData,
    loading: myBetsLoading,
    error: myBetsError,
  } = useMyBetsQuery({
    variables: betsFilters,
    onCompleted: (data) => {
      setShowLoader({ isTrue: true, loaderFor: loaderTypes.default });
      const groupedData = data?.players?.myBets?.items?.reduce((acc: any, item: any) => {
        if (!acc[item.createdAt]) {
          acc[item.createdAt] = [];
        }
        acc[item.createdAt].push(item);
        return acc;
      }, {});
      setMyBetsHistory(groupedData);
    },
  });

  const [activeTabId, setActiveTabId] = useState(gameJson.betHistoryFilterOptions[0].id);
  const onTabClick = (tabId: any) => {
    setActiveTabId(tabId);
    setBetsFilter((prev) => {
      return { ...prev, dateFilter: tabId };
    });
  };

  // return if got an error .....
  if (myBetsError) {
    return (
      <section className={classNames(styles.centerErrorMsg)}>
        <Typography color="white" fontFamily="avenirNextBold" size="lg">
          {sessionHelper?.content.myBetsError}
        </Typography>
      </section>
    );
  }

  const isBetAvailable = () => {
    let myBetsItem = myBetsData?.players?.myBets;
    // console.log(myBetsItem?.items.length);
    if (betsFilters?.dateFilter === DateFilterEnum.Today) {
      if (myBetsItem?.items.length === 0) {
        return true;
      }
      if (myBetsItem?.items.length === 1) {
        if (myBetsItem?.items[0].bets.length === 0) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      if (myBetsItem?.items.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <ContentShell loading={showLoader.isTrue && showLoader.loaderFor === loaderTypes.betHistory}>
      <section className={classNames(styles.betSlipRoot)}>
        <div className={classNames(styles.betSlipHeader)}>
          <Typography fontFamily="avenirNextSemiBold" color="white" size="lg">
            {sessionHelper?.content?.betHistory}
          </Typography>
        </div>
        <section
          className={classNames(styles.betsFilterList, {
            [styles.showHalfOlderTab]: activeTabId === DateFilterEnum.Last30Days,
            [styles.showFullOlderTab]: activeTabId === DateFilterEnum.Older,
          })}
        >
          {gameJson.betHistoryFilterOptions.map((data, tabsIndex) => (
            <div
              key={`${tabsIndex}-${data.id}`}
              onClick={() => onTabClick(data.id)}
              className={classNames(styles.list, {
                [styles.active]: data.id === activeTabId,
              })}
            >
              <Typography
                noWrap={true}
                color={data.id === activeTabId ? "purpleBlue" : "white"}
                fontFamily="avenirNextBold"
                size="md"
              >
                {getConfigLabel(data.label)}
              </Typography>
            </div>
          ))}
        </section>

        <section
          className={classNames(styles.betSlipContainer, {
            [styles.centerItems]: myBetsError,
          })}
        >
          {/* {Object.entries(myBetsHistory).map(([date, items]) => (
            <div key={date}>
              <h2>{date}</h2>
              <ul>
              
              </ul>
            </div>
          ))} */}
          {isBetAvailable() ? (
            <section className={classNames(styles.centerErrorMsg)}>
              <Typography color="white" size="md" fontFamily="avenirNextBold">
                {sessionHelper?.content.myBetsNotFound}
              </Typography>
            </section>
          ) : (
            myBetsData?.players?.myBets?.items?.map((data, index) => {
              if (data.bets.length === 0) return;
              return <BetSlip key={`${data.matchId}-${data.createdAt}`} betSlipData={data as any} />;
            })
          )}
        </section>
      </section>
    </ContentShell>
  );
};

export default Bets;
