import React, { ButtonHTMLAttributes, PropsWithChildren, useState } from "react";
import classNames from "classnames";
import styles from "styles/components/numbersKeypad.module.css";
import getConfig from "util/configHelper";
import Typography from "components/Typography";
import Button from "./Button";
import sessionHelper from "service/sessionHelper";
import gameJson from "config/gameJSON.json";

type Props = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    onClickOption?: (option: string | number) => void; // For default options
    onClickKey?: (key: string | number) => void; // For number keys
  }
>;

const NumbersKeypad = ({ onClickOption, onClickKey }: Props) => {
  const { configData } = getConfig();
  const [keyChange, setKeyChange] = useState("5");

  return (
    <section className={classNames(styles.keypadRoot)}>
      <div className={classNames(styles.defaultOptions)}>
        {gameJson.keyPadDefaultOptions.map((option, index) => (
          <Button
            isShowClickTransition={true}
            key={index}
            className={classNames(styles.option, {
              [styles.disbaledClick]: sessionHelper?.isFTU,
            })}
            onClick={() => onClickOption && onClickOption(option)} // Handle option click
          >
            <Typography size="lg" fontFamily="avenirNextBold" color="purpleBlue">
              £{option}
            </Typography>
          </Button>
        ))}
      </div>
      <div className={classNames(styles.keyValues)}>
        {gameJson.keypadNumberOptions.map((key, index) => (
          <div
            key={index}
            className={classNames(styles.key, {
              [styles.disbaledClick]: sessionHelper?.isFTU && key.key !== keyChange,
              [styles.highLightBox]: key.key === keyChange && sessionHelper?.isFTU,
            })}
            onClick={() => {
              onClickKey && onClickKey(key.key);
              setKeyChange("asdf");
            }} 
          >
            {key.key === "icon" ? (
              <img src="images/bets/keyPadDelete.png" alt="keypad delete" />
            ) : (
              <Typography color={key.key !== "." ? "black" : ""} size="2xl" fontFamily="avenirNextBold">
                {key.value}
              </Typography>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default React.memo(NumbersKeypad);
