import React from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Typography from "components/Typography";
import Modal from "components/Modal";
import useGameStore from "store/gameStore";
import getConfig from "util/configHelper";
import styles from "styles/components/modals/stakeInfoModal.module.css";
import { getConfigLabel, parseMessage } from "util/miscUtils";
import gameJson from "config/gameJSON.json";
import sessionHelper from "service/sessionHelper";

const StakeInfoModal = () => {
  const { configData } = getConfig();
  const { isOpenStakeInfoModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenStakeInfoModal: state.modalStates.isOpenStakeInfoModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleModalClose = () => {
    updateModalStates({ isOpenStakeInfoModal: false });
  };

  const percent =["20%","40%","100%"]

  return (
    <Modal
      id="stakeInfoModal"
      open={isOpenStakeInfoModal}
      handleClose={handleModalClose}
      footer={<></>}
      containerSize="lg"
    >
      <section className={classNames(styles.root)}>
        <div className={classNames(styles.row)}>
          <img className={classNames(styles.icon)} src="icon/infoGreen.png" alt="infoGreen" />
        </div>{" "}
        <div className={classNames(styles.row)}>
          <Typography position="center" fontFamily="avenirNextBold" size="xl">
            {sessionHelper?.content.xpBoost}
          </Typography>
        </div>
        <div className={classNames(styles.row)}>
          <Typography size="md" fontFamily="avenirNextMedium">
            {/* {sessionHelper?.content.xpBoostInfo.forEveryStake} */}
            {sessionHelper?.content?.forEvery}
            <span className={classNames(styles.fontBlack)}> {parseMessage({str:configData.euro,map:["£1"]})} </span>
            {sessionHelper?.content?.staked}{" "}
            <span className={classNames(styles.fontBlack)}>{sessionHelper?.content?.xp}</span>.
          </Typography>
          <Typography size="md" fontFamily="avenirNextMedium">
            {sessionHelper?.content.xpBoostedInfo}
          </Typography>
          {gameJson?.xpOddsInfoList.map((item, index) => (
            <div className={classNames(styles.list)} key={index}>
              <Typography fontFamily="avenirNextMedium"> • </Typography>
              <Typography size="md" fontFamily="avenirNextMedium" noWrap={true} addSpaceToRight={true}>
                {getConfigLabel(item.item)}
              </Typography>
              <Typography fontFamily="avenirNextMedium" addSpaceToRight={true}>
                <span className={classNames(styles.fontBlack)}>
                  {parseMessage({ str: getConfigLabel(item.percent), map: [percent[index]] })}
                </span>
              </Typography>
              <Typography size="md" fontFamily="avenirNextMedium">
                {getConfigLabel(item.boost)}
              </Typography>
            </div>
          ))}
        </div>
      </section>
    </Modal>
  );
};
export default React.memo(StakeInfoModal);
