import { forwardRef, Ref, useEffect, useImperativeHandle, useRef } from "react";
import classNames from "classnames";
import styles from "styles/components/carousal.module.css";
import { CarouselProps, CarouselRefHandler, GenericCarouselType, WithForwardRefType } from "core/interfaces/team";

export interface ICarouselNode<RecordOption = Record<string, unknown>> extends Record<string, unknown> {
  id: string;
  data: RecordOption;
}

type Props<RecordType extends object> = CarouselProps<RecordType>;

function CarouselWithRef<RecordType extends object>(
  {
    items,
    renderItem,
    autoScroll = false,
    pauseOnHover = false,
    delayStart = 1000,
    infinite = false,
  }: Props<RecordType>,
  carouselRef: Ref<CarouselRefHandler>,
) {
  const ref = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const mouseEnterRef = useRef<boolean>(false);

  // Get item width dynamically to avoid magic numbers
  const getItemWidth = () => {
    if (!ref.current?.firstChild) return 0;
    const firstChild = ref.current.firstChild as HTMLElement;
    const style = window.getComputedStyle(firstChild);
    return firstChild.offsetWidth + parseFloat(style.marginRight);
  };

  // Auto-scroll logic with smooth scrolling
  useEffect(() => {
    if (!autoScroll) return;

    const timeout = setTimeout(() => {
      const interval = 16.67; // 60fps for smooth scrolling
      intervalRef.current = setInterval(() => {
        if (ref.current && !mouseEnterRef.current) {
          ref.current.scrollLeft += 1;
        }
      }, interval);
    }, delayStart);

    const handleMouseEnter = () => {
      mouseEnterRef.current = true;
      if (intervalRef.current) clearInterval(intervalRef.current);
    };

    const handleMouseLeave = () => {
      mouseEnterRef.current = false;
      intervalRef.current = setInterval(() => {
        if (ref.current) {
          ref.current.scrollLeft += 1;
        }
      }, 16.67);
    };

    if (ref.current && pauseOnHover) {
      ref.current.addEventListener("mouseenter", handleMouseEnter);
      ref.current.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      clearTimeout(timeout);
      if (intervalRef.current) clearInterval(intervalRef.current);
      if (ref.current) {
        ref.current.removeEventListener("mouseenter", handleMouseEnter);
        ref.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [autoScroll, pauseOnHover, delayStart]);

  useImperativeHandle(
    carouselRef,
    () => ({
      snapToNext: () => handleOnSnapNext(),
      snapToPrev: () => handleOnSnapPrev(),
    }),
    [],
  );

  // Snap to the next item
  const handleOnSnapNext = () => {
    if (ref.current) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft + getItemWidth(),
        behavior: "smooth",
      });
    }
  };

  // Snap to the previous item
  const handleOnSnapPrev = () => {
    if (ref.current) {
      ref.current.scrollTo({
        left: ref.current.scrollLeft - getItemWidth(),
        behavior: "smooth",
      });
    }
  };

  // Infinite scroll behavior (dynamic repositioning)
  const handleOnScroll = () => {
    if (!ref.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = ref.current;

    if (scrollLeft === 0) {
      ref.current.scrollLeft = scrollWidth - 2 * clientWidth;
    } else if (scrollLeft + clientWidth >= scrollWidth) {
      ref.current.scrollLeft = clientWidth;
    }
  };

  useEffect(() => {
    if (!infinite || !ref.current) return;

    ref.current.addEventListener("scroll", handleOnScroll);
    return () => ref.current?.removeEventListener("scroll", handleOnScroll);
  }, [infinite]);

  const renderedItems = infinite ? [...Array(10)].flatMap(() => items) : items;

  return (
    <div className={classNames(styles.carouselContainer)}>
      <div className={classNames(styles.appCarousel)} ref={ref}>
        {renderedItems.map((item, index) => (
          <div key={index}>{renderItem(item, index)}</div>
        ))}
      </div>

      <div className={classNames(styles.carouselArrowContainer)}>
        <div onClick={handleOnSnapPrev} aria-label="Previous slide">
          <img src="icon/backArrow.png" alt="Previous" className={styles.carouselArrowImage} />
        </div>
        <div onClick={handleOnSnapNext} aria-label="Next slide">
          <img
            src="icon/backArrow.png"
            alt="Next"
            className={classNames(styles.carouselArrowImage, styles.carouselArrowImageRotate)}
          />
        </div>
      </div>
    </div>
  );
}

type UnknownRecordType = Record<string, unknown>;

const Carousel: WithForwardRefType<GenericCarouselType<UnknownRecordType>> = forwardRef<
  CarouselRefHandler,
  CarouselProps<GenericCarouselType<UnknownRecordType>>
>(CarouselWithRef);

export default Carousel;
