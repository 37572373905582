const errConfig = {
  GEN00: "Something went wrong!",
  GEN01: "Your session has expired. Please relogin",
  GEN03: "Team Name cannot be empty.",
  GEN04: "Please select country.",
  GEN05: "Team name should contain atleast 3 characters.",
  GEN06: "Player Not Found!.",
  GEN07:"Your purchase was Failed !!!",
  GEN08:"Something went wround try again later ..",

  PROF01: "The team name contains inappropriate language. Please try a different name.",

  Stake00: "Error Placing Bet.",
  Stake01: "Insufficient Funds.",
  Stake02: "Error Starting Match.",
  Stake03: "Facing Teachinal Error Please Try Again Later.",
  Stake04: "Error Fetching Odds List.",

  store00: "Packs Not Available.",
  store01: "Coins Not Available.",

  Succes01: "Team Name Edited Successfully.",
  Succes02: "Your purchase was successful.",
};

export default errConfig;
