import React, { HTMLAttributes, PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useInitMutation } from "generated/graphql";
import sessionHelper from "service/sessionHelper";
import appEventInstance from "util/eventHandler";
import { AppEventTypes, CanvasEvents, CanvasMessage } from "core/enums";
import Loader from "components/Loader";
import useGameStore from "store/gameStore";
import { generateRandomUserId, timer } from "util/miscUtils";

type SessionHelperProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>>;

function useQuery() {
  return window.location.href.split("?")[1];
}

const SessionHelper: React.FC<SessionHelperProps> = ({ children }) => {
  const { toggleCanvasReady } = useGameStore((state) => ({
    toggleCanvasReady: state.toggleCanvasReady,
  }));
  const [loading, setLoading] = useState(false);

  const canvasRef = React.useRef({ value: false });

  const _transferInfoToPixi = async (data: { type: string; value: any }) => {
    const iframeRef = useGameStore.getState().iframeRef;
    //@ts-ignore
    console.log("wrapper: _transferInfoToPixi", data, iframeRef, iframeRef?.contentWindow?.gameExtension);
    if (
      iframeRef &&
      //@ts-ignore
      iframeRef?.contentWindow
    ) {
      console.log("wrapper: _transferInfoToPixi to be send");
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension(data);
    }
  };

  useEffect(() => {
    const handleMessage = async (obj: any) => {
      if (obj?.data?.messageName === "CANVAS_READY") {
        console.log("wrapper:", "CANVAS_READY reveived");
        await _transferInfoToPixi?.({
          type: CanvasMessage.URLS,
          value: {
            apiUrl: process.env.REACT_APP_API_URL,
            socket: process.env.REACT_APP_SOCKET_URL,
            external: process.env.REACT_APP_EXTERNAL_URL,
          },
        });
        toggleCanvasReady(true);
        canvasRef.current.value = true;
      } else if (obj?.data?.messageName) {
        appEventInstance.dispatch({
          eventType: [CanvasEvents.showBack, CanvasEvents.showContinue, CanvasEvents.removeBack].includes(
            obj?.data?.messageName,
          )
            ? AppEventTypes.toogleFooter
            : AppEventTypes.canvasEvent,
          payload: { ...obj?.data },
        });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      //@ts-ignore
      window.removeEventListener("message", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? <Loader /> : <>{children}</>;
};

export default SessionHelper;
