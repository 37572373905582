import React, { useState } from "react";
import classNames from "classnames";
import Typography from "components/Typography";
import { extractCurrencySymbol, formatDateTime, setNumberFormat } from "util/miscUtils";
import styles from "styles/pages/bets/components/betSlip.module.css";
import sessionHelper from "service/sessionHelper";
import errConfig from "core/err";
import { BetStatus, OddsDisplayEnum } from "generated/graphql";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";

type BetItems = {
  betId: string;
  oddId: string;
  betAmount: number;
  winAmount: number;
  toReturn: number;
  marketType: string;
  marketName: string;
  xp: number;
  xpBoostPercentage: number;
  odd: string;
  decimalOdd: number;
  fractionalOdd: string;
  americanOdd: string;
  isResolved: boolean;
  betStatus: string;
  createdAt: string;
  oddsDetails: {
    win: string;
    goals: string;
    type: string;
    value: string;
    team1: string;
    team2: string;
    playerId: number;
    teamId: string;
    handicap: string;
    xpBoostPercentage: number;
  };
};

type Bet = {
  createdAt: string;
  myTeamName: string;
  opponentTeamName: string;
  bets: BetItems[];
  allPlayers: [];
};

type Props = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement> & {
    betSlipData: Bet;
  }
>;

const BetSlip = ({ betSlipData }: Props) => {

  const getTeamName = (name: string) => {
    if (name === "A") return betSlipData.myTeamName || "-";
    if (name === "B") return betSlipData.opponentTeamName || "-";
    return "Draw";
  };

  const { selectedOddsDisplay } = useGameStore(
    (state) => ({
      selectedOddsDisplay: state.selectedOddsDisplay,
    }),
    shallow,
  );

  const getPlayerName = (playerId: number | string, allPlayersList: any) => {
    let playerDetails = allPlayersList?.filter((ele: any, index: any) => ele.playerId === playerId);
    if (playerDetails?.length) {
      return playerDetails[0].name;
    } else {
      return errConfig.GEN06;
    }
  };

  function getOddsDisplay(betItems: any) {
    if (!selectedOddsDisplay) return "";

    switch (selectedOddsDisplay) {
      case OddsDisplayEnum.Fractional:
        return betItems.fractionalOdd.toString();
      case OddsDisplayEnum.Decimal:
        return betItems.decimalOdd.toString();
      case OddsDisplayEnum.American:
        return betItems.americanOdd.toString();
      default:
        return "";
    }
  }

  return (
    <div className={classNames(styles.betSlip)}>
      <header>
        <Typography size="md" fontFamily="avenirNextMedium">
          {`${betSlipData.myTeamName} v ${betSlipData.opponentTeamName}`}
        </Typography>
        <Typography size="xs" fontFamily="avenirNextMedium">
          {`${formatDateTime(betSlipData.createdAt, false)}`}
        </Typography>
      </header>
      {betSlipData.bets.map((betItems, betItemsIndex) => {
        return (
          <div key={`${betItemsIndex}-${betItems.betId}`} className={classNames(styles.betSlipItems)}>
            <div className={classNames(styles.col)}>
              <img
                className={styles.activeStatusIcon}
                src={`/images/bets/${betItems.isResolved ? "active" : "inActive"}.png`}
                alt="active btn"
              />
            </div>

            {/* =================== */}

            <div className={classNames(styles.col, styles.addGap)}>
              <Typography
                color="grayPlus"
                size={betItems.marketName.length > 25 ? "xs2" : betItems.marketName.length > 18 ? "md" : "md2"}
                fontFamily="avenirNextMedium"
              >
                {betItems.marketName}
              </Typography>
              <div
                className={classNames(styles.marketDetails, {
                  [styles.hide]: betItems.marketName !== sessionHelper?.content.finalResult,
                })}
              >
                {" "}
                <Typography color="white" fontFamily="avenirNextBold" size="md">
                  {getTeamName(betItems.oddsDetails.win as string)}
                </Typography>
                <Typography color="green" fontFamily="avenirNextBold" size="md">
                    {getOddsDisplay(betItems)}
                </Typography>
              </div>

              {/* ================= */}
              <div
                className={classNames(styles.marketDetails, {
                  [styles.hide]: betItems.marketName !== sessionHelper?.content.goalsOverUnder,
                })}
              >
                {" "}
                <Typography color="white" fontFamily="avenirNextBold" size="md">
                  {betItems.oddsDetails.type &&
                    betItems.oddsDetails.type.charAt(0).toUpperCase() + betItems.oddsDetails.type.slice(1)}
                </Typography>
                <Typography color="white" fontFamily="avenirNextBold" size="md">
                  {betItems.oddsDetails.goals}
                </Typography>{" "}
                <Typography color="green" fontFamily="avenirNextBold" size="md">
                  {getOddsDisplay(betItems)}
                </Typography>
              </div>

              {/* ================= */}

              <div
                className={classNames(styles.marketDetails, {
                  [styles.hide]: betItems.marketName !== sessionHelper?.content.bothTeamsToScore,
                })}
              >
                <Typography color="white" fontFamily="avenirNextBold" size="md">
                  {betItems.oddsDetails.value}
                </Typography>{" "}
                <Typography color="green" fontFamily="avenirNextBold" size="md">
                  {getOddsDisplay(betItems)}
                </Typography>
              </div>

              {/* ================= */}

              <div
                className={classNames(styles.marketDetails, {
                  [styles.hide]: betItems.marketName !== sessionHelper?.content.resultBothTeamsScore,
                })}
              >
                <Typography
                  color="white"
                  fontFamily="avenirNextBold"
                  size={betItems.betAmount > 5 ? "md2" : "md"}
                  noWrap={true}
                >
                  {getTeamName(betItems.oddsDetails.win as string)} / {betItems.oddsDetails.value}
                </Typography>
                <Typography color="green" fontFamily="avenirNextBold" size="md">
                  {getOddsDisplay(betItems)}
                </Typography>
              </div>

              {/* ================= */}

              <div
                className={classNames(styles.marketDetails, {
                  [styles.hide]: betItems.marketName !== sessionHelper?.content.correctScoree,
                  [styles.flexColDirection]: true,
                })}
              >
                <div>
                  {" "}
                  <Typography color="white" fontFamily="avenirNextBold" size="md">
                    {betItems.oddsDetails.team1} - {betItems.oddsDetails.team2}
                  </Typography>
                  <Typography color="green" fontFamily="avenirNextBold" size="md2">
                    {getOddsDisplay(betItems)}
                  </Typography>
                </div>
                <Typography color="white" fontFamily="avenirFont" size="md2">
                  {getTeamName(betItems.oddsDetails.win as string)}
                </Typography>
              </div>

              {/* ================= */}

              <div
                className={classNames(styles.marketDetails, {
                  [styles.hide]:
                    betItems.marketType !== sessionHelper?.content.firstLastAnytimeGoalScorerType,
                  [styles.flexColDirection]: true,
                })}
              >
                <div>
                  {" "}
                  <Typography color="white" fontFamily="avenirNextBold" size="md">
                    {getPlayerName(betItems.oddsDetails.playerId, betSlipData.allPlayers)}
                  </Typography>
                  <Typography color="green" fontFamily="avenirNextBold" size="md">
                    {getOddsDisplay(betItems)}
                  </Typography>
                </div>
                <Typography color="white" fontFamily="avenirFont" size="md2">
                  {getTeamName(betItems.oddsDetails.teamId as string)}
                </Typography>
              </div>

              {/* ================= */}

              <div
                className={classNames(styles.marketDetails, {
                  [styles.hide]: betItems.marketName !== sessionHelper?.content.handicaps,
                  [styles.flexColDirection]: true,
                })}
              >
                <div>
                  {" "}
                  <Typography color="white" fontFamily="avenirNextBold" size="md">
                    {getTeamName(betItems.oddsDetails.win as string)}
                  </Typography>{" "}
                  <Typography color="white" fontFamily="avenirNextBold" size="md">
                    {` ${betItems.oddsDetails.handicap}`}
                  </Typography>
                  <Typography color="green" fontFamily="avenirNextBold" size="md">
                    {getOddsDisplay(betItems)}
                  </Typography>
                </div>
              </div>

              {/* ================= */}

              <div
                className={classNames(styles.marketDetails, {
                  [styles.hide]:
                    betItems.marketName !== sessionHelper?.content.firstGoalScorerCorrectScore,
                  [styles.flexColDirection]: true,
                })}
              >
                <div className={styles.flexColDirection}>
                  {" "}
                  <Typography color="white" fontFamily="avenirNextBold" size="md">
                    {getPlayerName(betItems.oddsDetails?.playerId, betSlipData?.allPlayers)} /
                    {` ${betItems.oddsDetails.team1}-${betItems.oddsDetails.team2} `}
                  </Typography>
                  <Typography color="green" fontFamily="avenirNextBold" size="md">
                    {getOddsDisplay(betItems)}
                  </Typography>
                </div>
                <Typography color="white" fontFamily="avenirFont" size="md2">
                  {getTeamName(betItems.oddsDetails.teamId as string)}
                </Typography>
              </div>
              <Typography fontFamily="avenirNextMedium" size="xs2" color="grayPlus" noWrap={true}>
                {sessionHelper?.content.betRef}
                {betItems.betId}
              </Typography>
            </div>

            {/* =================== */}

            <div className={classNames(styles.col)}>
              <div className={classNames(styles.row)}>
                <Typography color="grayPlus" size="md2" fontFamily="avenirFont">
                  {sessionHelper?.content.stake}
                </Typography>
                <Typography
                  fontFamily="avenirNextBold"
                  size={
                    setNumberFormat(betItems.betAmount ?? 0, 2).length > 4
                      ? "xs"
                      : setNumberFormat(betItems.betAmount ?? 0, 2).length > 6
                        ? "xs2"
                        : "md"
                  }
                >
                  {extractCurrencySymbol("£")}
                  {setNumberFormat(betItems.betAmount ?? 0, 2)}
                </Typography>
              </div>
              <div className={classNames(styles.row)}>
                <Typography color="grayPlus" size="md2" fontFamily="avenirFont">
                  {sessionHelper?.content.returned}
                </Typography>
                <Typography
                  fontFamily="avenirNextBold"
                  size={setNumberFormat(betItems.toReturn ?? 0, 2).length > 6 ? "xs2" : "md"}
                  color={betItems.toReturn > 0 ? "green" : "white"}
                >
                  {extractCurrencySymbol("£")}
                  {setNumberFormat(betItems.toReturn ?? 0, 2)}
                </Typography>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BetSlip;
