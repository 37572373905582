import React, { useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import styles from "styles/components/drawers/settingsDrawer.module.css";
import Typography from "components/Typography";
import Drawer from "components/Drawer";
import { CanvasMessage, DrawerPositions, ZIndexLevels } from "core/enums";
import useGameStore from "store/gameStore";
import getConfig from "util/configHelper";
import { OddsDisplayEnum, useUpdateUserSettingsMutation, useMeQuery } from "generated/graphql";
import sessionHelper from "service/sessionHelper";
import { getVersion } from "util/miscUtils";
import appEventInstance from "util/eventHandler";
import _Error, { ErrorMessage } from "util/ErrHandler";
import { AppEventTypes } from "core/enums";
import Button from "components/Button";
import { getConfigLabel } from "util/miscUtils";
import gameJson from "config/gameJSON.json";

const SettingsDrawer: React.FC<{}> = () => {
  const [selectedOption, setSelectedOption] = useState<string>(OddsDisplayEnum.Fractional);

  const [isChecked, setIsChecked] = useState(true);
  const [updateUserSettingsMutation] = useUpdateUserSettingsMutation();
  const { isOpenSettingsDrawer, updateDrawerStates, updateModalStates, setSelectedOddsDisplay, selectedOddsDisplay } =
    useGameStore(
      (state) => ({
        isOpenSettingsDrawer: state.drawerStates.isOpenSettingsDrawer,
        updateDrawerStates: state.updateDrawerStates,
        updateModalStates: state.updateModalStates,
        setSelectedOddsDisplay: state.setSelectedOddsDisplay,
        selectedOddsDisplay: state.selectedOddsDisplay,
      }),
      shallow,
    );

  const { refetch, data } = useMeQuery({
    skip: !isOpenSettingsDrawer,
    onCompleted: (data) => {
      console.log({ data });
      let _oddType = data?.players?.me?.settings?.oddsDisplay;
      setSelectedOddsDisplay(_oddType);
      setIsChecked(data?.players?.me?.settings?.audio);
    },
  });

  const [version, setVersion] = useState("");

  const updateSettings = async (updatedSettings: { oddsDisplay: OddsDisplayEnum; audio: boolean }) => {
    try {
      const resp = await updateUserSettingsMutation({
        variables: { input: updatedSettings },
      });
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({
        eventType: AppEventTypes.toggleErrorModal,
        payload: { msg: errMsg?.message },
      });
    }
  };

  const handleRadioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
    setSelectedOddsDisplay(newSelectedOption as string);
    //@ts-ignore
    await _transferInfoToPixi?.({
      type: CanvasMessage.oddsType,
      value: { oddsType: newSelectedOption },
    });
    await updateSettings({ oddsDisplay: newSelectedOption as OddsDisplayEnum, audio: isChecked });
  };

  const handleToggle = async () => {
    const newIsChecked = !isChecked;
    setIsChecked(newIsChecked);
    //@ts-ignore
    await _transferInfoToPixi?.({
      type: CanvasMessage.isAudioOn,
      value: { isAudioOn: newIsChecked },
    });
    await updateSettings({ oddsDisplay: selectedOption as OddsDisplayEnum, audio: newIsChecked });
  };

  const _transferInfoToPixi = async (data: { type: string; value: any }) => {
    const iframeRef = useGameStore.getState().iframeRef;
    //@ts-ignore
    console.log("wrapper: _transferInfoToPixi", data, iframeRef, iframeRef?.contentWindow?.gameExtension);
    if (
      iframeRef &&
      //@ts-ignore
      iframeRef?.contentWindow
    ) {
      console.log("wrapper: _transferInfoToPixi to be send");
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension(data);
    }
  };

  const toggleModal = () => {
    updateModalStates({ isOpenGameRulesModal: true });
  };

  const _handleResetGame = () => {
    window.localStorage.removeItem("userId");
    window.location.reload();
  };

  const handleClose = () => {
    if (isOpenSettingsDrawer) {
      updateDrawerStates({ isOpenSettingsDrawer: false });
    }
  };

  //In Local value will be hardcoded please ignore
  useEffect(() => {
    getVersion().then(setVersion);
  }, []);

  return (
    <Drawer
      isOpen={isOpenSettingsDrawer}
      position={DrawerPositions.Bottom}
      id="isOpenSettingsDrawerClose"
      onClose={handleClose}
      showFooterButton={true}
      zIndexLevel={ZIndexLevels.Level2}
      header={
        <div className={classNames(styles.header)}>
          <Typography fontFamily="degularBold" size="2xl">
            {sessionHelper?.content?.setting}
          </Typography>
        </div>
      }
    >
      <section className={styles.box}>
        <div className={classNames(styles.root)}>
          <div className={classNames(styles.backGround)}>
            <div className={classNames(styles.upperRect)}>
              <Typography fontWeight="font700" fontFamily="avenirNextSemiBold">
                {sessionHelper?.content?.oddsDisplay}
              </Typography>
            </div>
            {gameJson.oddsDisplay?.map((item, index) => (
              <div key={index} className={classNames(styles.marginBtm, styles.borderOddsDisplay)}>
                <div className={classNames(styles.firstDiv)}>
                  <div className={classNames(styles.flexRow, { [styles.selected]: selectedOption === item.label })}>
                    <Typography
                      fontFamily="avenirNextSemiBold"
                      color={item.label === selectedOddsDisplay ? "white" : "gray"}
                    >
                      {getConfigLabel(item.label)}
                    </Typography>
                    <Typography
                      fontFamily="avenirNextSemiBold"
                      color={item.label === selectedOddsDisplay ? "white" : "gray"}
                    >{`(${item.value})`}</Typography>
                  </div>
                  <label className={classNames(styles.radioContainer)}>
                    <input
                      type="radio"
                      name="settings"
                      value={item.label}
                      checked={selectedOption === item.label}
                      onChange={handleRadioChange}
                      className={classNames(styles.hiddenRadio)}
                    />
                    <div
                      className={classNames(styles.customRadio, {
                        [styles.customRadioChecked]: selectedOddsDisplay === item.label,
                      })}
                    ></div>
                  </label>
                </div>
              </div>
            ))}
          </div>
          <div className={classNames(styles.backGround)}>
            <div className={classNames(styles.upperRect)}>
              <Typography fontWeight="font700" fontFamily="avenirNextSemiBold">
                {sessionHelper?.content?.sounds}
              </Typography>
            </div>
            <div className={classNames(styles.secondDiv)}>
              <Typography fontFamily="avenirNextMedium">{sessionHelper?.content?.audio}</Typography>
              <label className={styles.toggleSwitch}>
                <input type="checkbox" checked={isChecked} onChange={handleToggle} className={styles.hiddenInput} />
                <div
                  className={classNames(styles.slider, {
                    [styles.checked]: isChecked,
                  })}
                />
              </label>
            </div>
          </div>
          <div className={classNames(styles.backGround)}>
            <div className={classNames(styles.upperRect)}>
              <Typography fontWeight="font700" fontFamily="avenirNextSemiBold" size="md">
                {sessionHelper?.content?.help}
              </Typography>
            </div>
            <div className={classNames(styles.secondDiv)} onClick={toggleModal}>
              <Typography fontFamily="avenirNextMedium">{sessionHelper?.content?.gameRule}</Typography>
              <img src="icon/backArrow.png" alt="backArrow" className={classNames(styles.arrowIcon)} />
            </div>
          </div>

          <Button
            onClick={_handleResetGame}
            isShowClickTransition={true}
            displayFlex={true}
            size="stretch"
            mediumHeight={true}
          >
            <Typography addSpaceToRight={true} fontFamily="degularBold" size="xl" color="purpleBlack">
              {sessionHelper?.content.ResetGameBtn}
            </Typography>
            {/* <img src="icon/reset.png" alt="backArrow" className={styles.resetIcon} /> */}
          </Button>

          <div className={classNames(styles.flexCol)}>
            <img src="icon/exitButton.png" alt="exitButton" className={classNames(styles.exitButton)} />
            <Typography fontFamily="degularBold">{sessionHelper?.content?.exit}</Typography>
          </div>
        </div>
        <div className={styles.version}>
          <Typography fontFamily="degularMedium">{version}</Typography>
        </div>
      </section>
    </Drawer>
  );
};

export default React.memo(SettingsDrawer);
