// import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";

// const client = new ApolloClient({
//   link: new HttpLink({
//     uri: process.env.REACT_APP_API_URL + '/graphql',
//     credentials: "include",
//   }),
//   cache: new InMemoryCache(),
//   credentials: "include",

//   // This overrides the cache, it disables the cache, not recommended for prod
//   defaultOptions: {
//     watchQuery: {
//       fetchPolicy: "no-cache",
//       errorPolicy: "all",
//     },
//     query: {
//       fetchPolicy: "no-cache",
//       errorPolicy: "all",
//     },
//   },
// });

// export default client;

import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import sessionHelper from "service/sessionHelper";

// Step 1: Set up the HttpLink to point to your GraphQL API
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL + "/graphql",
  credentials: "include", // This sends cookies with requests
});

// Step 2: Set up the authLink to attach the `beToken` to the headers
const authLink = setContext((_, { headers }) => {
  const token = sessionHelper.beToken;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "", // Attach the token if it exists
    },
  };
});

// Step 3: Create the ApolloClient instance and combine the authLink and httpLink
const client = new ApolloClient({
  link: authLink.concat(httpLink), // Combine the authLink with the HttpLink
  cache: new InMemoryCache(),

  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache", // Always fetch fresh data
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache", // Disable cache for queries
      errorPolicy: "all",
    },
  },
});

export default client;
