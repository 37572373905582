import classNames from "classnames";
import { shallow } from "zustand/shallow";
import Typography from "components/Typography";
import getConfig from "util/configHelper";
import styles from "styles/components/vault.module.css";
import React, { ButtonHTMLAttributes, PropsWithChildren, useEffect, useState } from "react";
import useGameStore from "store/gameStore";
import { timer } from "util/miscUtils";
import NumberCounter from "./NumberCounter";

type Props = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    hide?: boolean;
  }
>;

const Vault = ({ hide = false }: Props) => {
  const { configData } = getConfig();
  const { betPlaceData } = useGameStore(
    (state) => ({
      betPlaceData: state.betPlaceData,
    }),
    shallow,
  );

  let vaultData = betPlaceData?.players?.placeBet;
  const [progressWidth, setProgressWidth] = React.useState(`0%`);
  const [rotatePbfPacket, setRotatePbfPacket] = React.useState(false);
  const [vaultValueCounterHandler, setVaultValueCounterHandler] = React.useState({
    vaultCurrentXp: 0,
    vaultTotalXp: 0,
    vaultLevel: 0,
    xpFrom: betPlaceData?.players?.placeBet?.progressUpdates[0].xpFrom,
  });

  const durationPerLevel = 650; // Duration for each level fill

  const calculatePercentage = (xpRequired: any, xpTo: any) => {
    if (xpTo && xpRequired) {
      return (xpTo / xpRequired) * 100;
    }
    return 0;
  };
  let prevSpinRef = React.useRef<number>(0);

  const handleUpdateVaultAnimation = async () => {
    let vaultProgressUpdates = betPlaceData?.players?.placeBet?.progressUpdates;
    if (vaultProgressUpdates) {
      for (let i = 0; i < vaultProgressUpdates?.length; i++) {
        setRotatePbfPacket(true);

        if (i < vaultProgressUpdates.length - 1) {
          setVaultValueCounterHandler({
            vaultCurrentXp: vaultProgressUpdates[i].xpTo,
            vaultTotalXp: vaultProgressUpdates[i].xpRequired,
            vaultLevel: vaultProgressUpdates[i].level,
            xpFrom: vaultProgressUpdates[i].xpFrom,
          });
          setProgressWidth("100%");
          await timer(durationPerLevel); // Wait for animation to complete
          setProgressWidth("0%"); // Reset progress bar for the next level
          prevSpinRef.current = prevSpinRef.current + 1;
          await timer(30); // Small delay for a smooth reset effect
          setRotatePbfPacket(false);
        } else {
          setRotatePbfPacket(true);
          // Reset progress bar to 0% first (important for smooth animation);
          const initialPercentage = calculatePercentage(
            vaultProgressUpdates[i].xpRequired,
            vaultProgressUpdates[i].xpFrom,
          );
          setProgressWidth(`${initialPercentage}%`);
          await timer(30); // Small delay for reset effect

          const finalPercentage = calculatePercentage(vaultProgressUpdates[i].xpRequired, vaultProgressUpdates[i].xpTo);

          setVaultValueCounterHandler({
            vaultCurrentXp: vaultProgressUpdates[i].xpTo,
            vaultTotalXp: vaultProgressUpdates[i].xpRequired,
            vaultLevel: vaultProgressUpdates[i].level,
            xpFrom: vaultProgressUpdates[i].xpFrom + i,
          });

          // Now apply the final animation
          prevSpinRef.current = prevSpinRef.current + 1;

          setProgressWidth(`${finalPercentage}%`);
          await timer(650);
          setRotatePbfPacket(false);
        }
      }
    }
  };

  useEffect(() => {
    handleUpdateVaultAnimation();
    let availableSpins = betPlaceData?.players?.placeBet?.availableSpins as number;
    let spinsIncreased = betPlaceData?.players?.placeBet?.progressUpdates?.length as number;
    prevSpinRef.current = availableSpins - spinsIncreased;
    // console.log({ availableSpins, spinsIncreased });

    return () => {
      setProgressWidth("0%"); // Reset progress bar for the next level
    };
  }, [betPlaceData]);

  return (
    <section
      className={classNames(styles.vaultContainer, {
        [styles.hide]: hide,
      })}
    >
      <div
        className={classNames(styles.badgeLevel, {
          [styles.hide]: vaultValueCounterHandler?.vaultLevel < 1,
        })}
      >
        <Typography color="black" fontFamily="degularBold" size="xs">
          {prevSpinRef.current}
        </Typography>
      </div>
      <div className={styles.colRowContainer}>
        <div className={styles.row}>
          <Typography fontFamily="degularBlack" size="xl1">
            {configData.TheVaultText}
          </Typography>
          <Typography fontFamily="avenirNextSemiBold" size="md2">
            {configData.xpText}
            <NumberCounter
              from={(vaultValueCounterHandler?.xpFrom as number) || 0}
              to={vaultValueCounterHandler?.vaultCurrentXp as number}
            />
          </Typography>
          <Typography
            fontFamily="avenirNextSemiBold"
            color={
              vaultValueCounterHandler?.vaultCurrentXp === vaultValueCounterHandler.vaultTotalXp ? "white" : "gray"
            }
            size="md2"
          >
            /{vaultValueCounterHandler?.vaultTotalXp}
          </Typography>
        </div>
        <div className={styles.row}>
          <div className={styles.outerProgressBar}>
            <div
              className={classNames(styles.valueBar, {
                [styles.innerProgressBar]: progressWidth !== "0%",
              })}
              style={{ width: `${progressWidth}` }}
            ></div>
            <div className={styles.pointer}></div>
          </div>
          <img
            src={"images/bets/vault.png"}
            alt="pbPacket"
            className={classNames(styles.pbPacket, {
              [styles.rotatePbfPacket]: rotatePbfPacket,
            })}
          />
        </div>
      </div>
      {/* <button onClick={() => handleUpdateVaultAnimation()}>
        <Typography>run animation</Typography>
      </button> */}
    </section>
  );
};

export default React.memo(Vault);
