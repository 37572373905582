import { HTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "styles/components/typography.module.css";

type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    color?: string;
    fontFamily?: string;
    fontWeight?: string;
    size?: string;
    transform?: boolean;
    hide?: boolean;
    addSpaceToRight?: boolean;
    hideVisibility?: boolean;
    noWrap?: boolean;
    isCapitalizeStartLetter?: boolean;
    wrap?: boolean;
    position?: string;
  }
>;

const Typography = ({
  size = "",
  color = "white",
  fontWeight = "",
  hide = false,
  hideVisibility = false,
  transform = false,
  fontFamily = "",
  addSpaceToRight = false,
  noWrap = false,
  isCapitalizeStartLetter = false,
  wrap = false,
  children,
  position = "",
  ...props
}: Props) => {
  return (
    <p
      className={classNames(styles.root, {
        [styles.avenirFont]: fontFamily === "avenirFont", // for regular font weight 400
        [styles.avenirNextMedium]: fontFamily === "avenirNextMedium", // for medium 500
        [styles.avenirNextSemiBold]: fontFamily === "avenirNextSemiBold", // for font weight 600
        [styles.avenirNextBold]: fontFamily === "avenirNextBold", // for font weight 700
        [styles.avenirNextBlack]: fontFamily === "avenirNextBlack", // for font weight 900
        [styles.hide]: hide,
        [styles.degular]: fontFamily === "degular", // for regular font weight 400
        [styles.degularMedium]: fontFamily === "degularMedium", // for medium 500
        [styles.degularSemiBold]: fontFamily === "degularSemiBold", // for font weight 600
        [styles.degularBold]: fontFamily === "degularBold", // for font weight 700
        [styles.degularBlack]: fontFamily === "degularBlack", // for font weight 900
        [styles.font900]: fontWeight === "font900",
        [styles.font800]: fontWeight === "font800",
        [styles.font700]: fontWeight === "font700",
        [styles.normal]: fontWeight === "normal",
        [styles.navyBlue]: color === "navyBlue",
        [styles.black]: color === "black",
        [styles.gray]: color === "gray",
        [styles.grayPlus]: color === "grayPlus",
        [styles.blackishGray]: color === "blackishGray",
        [styles.white]: color === "white",
        [styles.green]: color === "green",
        [styles.purple]: color === "purple",
        [styles.yellow]: color === "yellow",
        [styles.purpleBlue]: color === "purpleBlue",
        [styles.purpleBlue300]: color === "purpleBlue300",
        [styles.purpleBlue700]: color === "purpleBlue700",
        [styles.xs3]: size === "xs3", // 8px
        [styles.xs4]: size === "xs4", // 8px
        [styles.xs2]: size === "xs2", // 10px
        [styles.xs2_5]: size === "xs2_5", // 9.5px
        [styles.xs1]: size === "xs1", // 11px
        [styles.xs]: size === "xs", // 12px
        [styles.md2]: size === "md2", // 14px
        [styles.md]: size === "md", // 16px
        [styles.lg]: size === "lg", // 18px
        [styles.lg_noLineHeight]: size === "lg_noLineHeight", // 18px
        [styles.xl]: size === "xl", // 20px
        [styles.xl1]: size === "xl1", // 22px
        [styles.xl2]: size === "2xl", // 24px
        [styles.xl3]: size === "3xl", // 30px
        [styles.transform]: transform,
        [styles.addSpaceToRight]: addSpaceToRight,
        [styles.capitalize]: isCapitalizeStartLetter,
        [styles.noWrap]: noWrap,
        [styles.wrap]: wrap,
        [styles.positionLeft]: position === "Left",
        [styles.hideVisibility]: hideVisibility,
        // [styles.disabledViewText] : sessionHelper?.isFTU || true // for now
      })}
      {...props}
    >
      {children}
    </p>
  );
};

export default Typography;
