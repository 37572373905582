import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en", // Default language
  debug: false, // Enable debug mode during development
  resources: {}, // Initially empty, translations will be injected dynamically
});

export default i18n;
