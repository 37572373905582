import React, { useEffect, useState, InputHTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "styles/components/textFeild.module.css";
import Typography from "components/Typography";
import { extractCurrencySymbol } from "util/miscUtils";

type Props = PropsWithChildren<
  InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    topLabel?: string;
    labelColor?: string;
    border?: string;
    displayFlex?: boolean;
    value?: string | number;
    disabled?: boolean;
    addBorder?: boolean;
    isHideInsideLabel?: boolean;
    isHideTopLabel?: boolean;
    noDefaultKeypad?: boolean;
    isHideErrorLabel?: boolean;
    isStakeInputHeight?: boolean;
    isLargeText?: boolean;
    backGroundColor?: string;
    actualBetAmount?: any;
    maxLength?: number;
    labelPosition?: string;
    valueColor?: string;
    borderColor?: string;
    errorLabel?: string;
    defaultKeyOrNumberKey?: String;
    startIcon?: string;
    endIcon?: React.ReactNode;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }
>;

const TextFeild = ({
  disabled = false,
  value = "",
  addBorder = false,
  label = "default",
  topLabel = "",
  onChange,
  borderColor = "",
  name = "",
  labelColor = "white",
  backGroundColor = "#2F2541",
  errorLabel = "",
  isHideInsideLabel = false,
  isHideTopLabel = true,
  isLargeText = false,
  isStakeInputHeight = false,
  isHideErrorLabel = true,
  valueColor = "",
  placeholder = "0.00",
  startIcon = "",
  actualBetAmount = {},
  endIcon = null,
  noDefaultKeypad = false,
  defaultKeyOrNumberKey = "keypadKeys",
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
  };

  // console.log({ value });
  return (
    <section className={classNames(styles.root)}>
      <Typography hide={isHideTopLabel} fontFamily="degularBold" size="xl" position="Left">
        {topLabel}
      </Typography>
      <div
        className={classNames(styles.subRoot, {
          [styles.addBorder]: addBorder,
          [styles.purpleBlueBorder]: borderColor === "purpleBlue",
          [styles.lightGrayBorder]: borderColor === "lightGray",
          [styles.lightGrayBg]: backGroundColor === "lightGrayBg",
          [styles.addTopSpacing]: !isHideTopLabel,
          [styles.stakeInputHeight]: isStakeInputHeight,
        })}
      >
        <Typography hide={isHideInsideLabel} fontFamily="avenirNextMedium" size="xs" color={labelColor} position="Left">
          {label}
        </Typography>
        <div className={classNames(styles.inputContainer)}>
          <input
            className={classNames(styles.input, {
              [styles.purpleBlue]: valueColor === "purpleBlue",
              [styles.purpleBlue500]: valueColor === "purpleBlue500",
            })}
            autoComplete="off"
            onChange={handleChange}
            disabled={disabled}
            type="text"
            name={name}
            value={
              actualBetAmount.length == 0
                ? `${extractCurrencySymbol("£")}0.00`
                : `${extractCurrencySymbol("£")}${value === 0 ? "0.00" : value}` // intaillly on any odd selected the value will be of type Number once clicked on any number in keypad it will be coverted to string
            }
            maxLength={10}
            readOnly={noDefaultKeypad}
          />
          <div className={classNames(styles.endIcon, { [styles.hide]: !startIcon })}>{endIcon}</div>
        </div>
      </div>
      <Typography fontFamily="degularBold" size="xs" position="Left" hide={isHideErrorLabel}>
        {errorLabel}
      </Typography>
    </section>
  );
};

export default TextFeild;
