import classNames from "classnames";
import { useState, useEffect, useRef, useCallback } from "react";
import { shallow } from "zustand/shallow";
import Carousel, { ICarouselNode } from "components/Carousal";
import Tabs from "components/Tab";
import Typography from "components/Typography";
import Button from "components/Button";
import Drawer from "components/Drawer";
import useGameStore from "store/gameStore";
import { debounce, formatDataToCarousalNodeType } from "util/miscUtils";
import getConfig from "util/configHelper";
import { DrawerBgType, TabType, DrawerPositions, ZIndexLevels, AppEventTypes } from "core/enums";
import { CardItem, MyTeamsResponse } from "core/interfaces/home";
import styles from "styles/components/drawers/teamEditDrawer.module.css";
import { TeamBadge, useProfanityCheckLazyQuery, useUpdateTeamMetadataMutation } from "generated/graphql";
import _Error, { ErrorMessage } from "util/ErrHandler";
import appEventInstance from "util/eventHandler";
import { useImagesPreloaded } from "hooks/usePreloader";
import Loader from "components/Loader";
import gameJson from "config/gameJSON.json";
import { getConfigLabel } from "util/miscUtils";
import errConfig from "core/err";
import sessionHelper from "service/sessionHelper";

const TeamEditDrawer = () => {
  const { errConfigData } = getConfig();
  const { isOpenTeamEditDrawer, updateDrawerStates, myTeamResponse, setMyTeamResponse, updateTeamName, allBadgesList } =
    useGameStore(
      (state) => ({
        isOpenTeamEditDrawer: state.drawerStates.isOpenTeamEditDrawer,
        updateDrawerStates: state.updateDrawerStates,
        myTeamResponse: state.myTeamResponse,
        setMyTeamResponse: state.setMyTeamResponse,
        updateTeamName: state.updateTeamName,
        allBadgesList: state.allBadgesList,
      }),
      shallow,
    );
  const [activeTab, setActiveTab] = useState<string>(gameJson.teamEditTabOption[0]?.id);
  const [kits, setKits] = useState<ICarouselNode<CardItem>[]>([]);
  const [badges, setBadges] = useState<ICarouselNode<TeamBadge>[]>([]);
  const [selectedKit, setSelectedKit] = useState<ICarouselNode<CardItem> | undefined>();
  const [selectedBadge, setSelectedBadge] = useState<ICarouselNode<TeamBadge> | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(true);
  const kitsImageUrls = gameJson.kits?.map((kit: any) => kit.url) || [];
  const badgesImageUrls = allBadgesList?.map((badge: any) => badge.url) || [];
  const allImagesLoaded = useImagesPreloaded([...kitsImageUrls, ...badgesImageUrls]);
  const initialTeamName = useRef<string>(myTeamResponse?.name as string); // Store the initial value once

  const [checkProfanity] = useProfanityCheckLazyQuery();
  const [UpdateTeamMetadataMutation] = useUpdateTeamMetadataMutation();

  const TabNames = gameJson.teamEditTabOption.map((tab) => ({
    id: tab.id,
    label: getConfigLabel(tab.label),
  }));

  useEffect(() => {
    if (allImagesLoaded) {
      console.log("All carousel images loaded");
      setLoading(false);
    }
    if (myTeamResponse?.name) {
      initialTeamName.current = myTeamResponse?.name;
    }
  }, [allImagesLoaded]);

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.populateTeamEdit, (data) => {
      const _badgeList: TeamBadge[] = [...data.detail.badgeList];
      const initializedKits = formatDataToCarousalNodeType(gameJson.kits || []);
      const initializedBadges = formatDataToCarousalNodeType(_badgeList || []);
      const _data = { ...data.detail.data };

      setKits(initializedKits);
      setBadges(initializedBadges);

      if (!selectedKit) {
        const _kit = initializedKits.find((el) => el.id === _data?.kit?.split(".")[0]);
        setSelectedKit(_kit);
      }
      if (!selectedBadge) {
        const _badge = initializedBadges.find((el) => el.id === _data?.badge?.split(".")[0]);
        setSelectedBadge(_badge);
      }
    });
    if (myTeamResponse?.name) {
      initialTeamName.current = myTeamResponse?.name;
    }

    return () => {
      appEventInstance.remove(AppEventTypes.populateTeamEdit);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendTeamInfoToCanvas = (data: Partial<MyTeamsResponse>) => {
    const iframeRef = useGameStore.getState().iframeRef;

    if (
      iframeRef &&
      //@ts-ignore
      iframeRef?.contentWindow &&
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension
    ) {
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension({
        type: "TEAM_INFO",
        value: {
          kit: data?.kit,
          badge: data?.badge,
          name: data?.name,
          kitColor: data?.kitColor,
          kitColorDark: data?.kitColorDark,
        },
      });
    }
  };

  const renderItem = (item: ICarouselNode<CardItem>, index: number) => {
    const isSelected =
      activeTab === gameJson.teamEditTabOption[0]?.id ? selectedKit?.id === item?.id : selectedBadge?.id === item?.id;
    return (
      <div key={index} className={styles.arrows}>
        <div
          className={classNames(styles.carousel, { [styles.selected]: isSelected })}
          onClick={() => handleSelect(item)}
        >
          <img
            src={item?.data?.url as string}
            alt={`item-${index}`}
            className={classNames({
              [styles.carouselKitImg]: activeTab === gameJson.teamEditTabOption[0]?.id,
              [styles.carouselEmblemImg]: activeTab === gameJson.teamEditTabOption[1]?.id,
            })}
          />
        </div>
      </div>
    );
  };

  const handleSelect = (item: ICarouselNode<CardItem>) => {
    if (activeTab === gameJson.teamEditTabOption[0]?.id) {
      setSelectedKit(item);
    } else if (activeTab === gameJson.teamEditTabOption[1]?.id) {
      setSelectedBadge(item);
    }
  };

  const handleEditClick = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0); // Delay to allow state to update
  };

  const handleNameSave = async (teamName: any) => {
    try {
      if (!teamName) {
        updateTeamName({ name: initialTeamName.current as string });
        throw new _Error({ message: errConfigData.GEN03, toastPosition: "bottom" });
      }
      if (teamName?.trim()?.length < 3) throw new _Error({ message: errConfigData.GEN05, toastPosition: "bottom" });

      let _res = await checkProfanity({ variables: { text: teamName } });

      if (!_res?.data?.players?.profanityCheck?.isValid) {
        updateTeamName({ name: initialTeamName.current as string });
        throw new _Error({ message: errConfigData.PROF01, toastPosition: "bottom" });
      }

      const response = await UpdateTeamMetadataMutation({
        variables: { input: { name: teamName as string } },
      });

      const data = response.data?.teamManager.updateTeamMetadata;
      if (data?.name) {
        initialTeamName.current = data?.name;
      }
      appEventInstance.dispatch({
        eventType: AppEventTypes.toggleErrorModal,
        payload: { msg: errConfig.Succes01, success: true },
      });

      // sending team info to canvas
      sendTeamInfoToCanvas(data as any);
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({ eventType: AppEventTypes.toggleErrorModal, payload: { msg: errMsg?.message } });
    }
  };

  const onTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  const handleClose = async () => {
    updateDrawerStates({ isOpenTeamEditDrawer: false });
    initialTeamName.current = myTeamResponse?.name as string; // hold updated team name
  };

  const handleSelectClick = async () => {
    try {
      // updating kit and badges
      const resp = await UpdateTeamMetadataMutation({
        variables: {
          input: {
            kit: selectedKit?.id,
            name: myTeamResponse?.name as string,
            badge: selectedBadge?.id,
          },
        },
      });

      const data = resp.data?.teamManager.updateTeamMetadata;

      // update team details in gamestores
      setMyTeamResponse(data as any);

      // sending team info data to canvas
      sendTeamInfoToCanvas(data as any);

      updateDrawerStates({ isOpenTeamEditDrawer: false });
    } catch (error) {
      const errMsg = error as ErrorMessage;
      appEventInstance.dispatch({ eventType: AppEventTypes.toggleErrorModal, payload: { msg: errMsg?.message } });
    }
  };

  const debouncedApiCall = useCallback(
    debounce(async (teamName: any) => {
      await handleNameSave(teamName);
    }, 1000), // Delay
    [],
  );

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    updateTeamName({ name: value });
    debouncedApiCall(e.target.value);
  };

  return (
    <Drawer
      id="teamEditDrawer"
      onClose={handleClose}
      isOpen={isOpenTeamEditDrawer}
      position={DrawerPositions.Top}
      showFooterButton={true}
      drawerBgType={DrawerBgType.TeamEdit}
      zIndexLevel={ZIndexLevels.Level1}
    >
      <div className={classNames(styles.mainDiv)}>
        <div className={classNames(styles.firstDiv)}>
          <input
            ref={inputRef}
            type="text"
            value={myTeamResponse?.name ?? ""}
            onChange={handleNameChange}
            autoFocus
            onInput={(e) => {
              const target = e.target as HTMLInputElement;
              target.value = target.value.replace(/[^a-zA-Z0-9\s'’]/g, ""); // Allows spaces
            }}
            maxLength={10}
            className={classNames(styles.teamNameInput)}
          />
          <div onClick={handleEditClick}>
            <img src="images/team/editButton.png" alt="editBtn" className={classNames(styles.editButton)} />
          </div>
        </div>
        <div className={classNames(styles.secondDiv)}>
          <img src={selectedKit?.data?.url} alt="tshirtImg" className={classNames(styles.tshirtImg)} />
          <img src={selectedBadge?.data?.url} alt="badgeImg" className={classNames(styles.emblemImg)} />
        </div>
        <div className={classNames(styles.tabs)}>
          <Tabs
            type={TabType.Square}
            onClick={(tabId) => onTabClick(tabId)}
            tabNames={TabNames}
            activeTab={activeTab}
            drawerOpen={isOpenTeamEditDrawer}
          />
        </div>
        <div className={classNames(styles.thirdDiv)}>
          {loading ? (
            <Loader />
          ) : (
            <Carousel
              pauseOnHover
              showDots={true}
              infinite={true}
              items={activeTab === "kits" ? kits : badges}
              renderItem={renderItem}
            />
          )}
        </div>
        <div className={classNames(styles.selectButton)}>
          <Button size="medium" color="greenBtn" onClick={handleSelectClick}>
            <Typography color="navyBlue" size="lg" fontFamily="degularBlack">
              {sessionHelper?.content?.select}
            </Typography>
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default TeamEditDrawer;
