import { create } from "zustand";
import { DrawerSizes, matchStatusEnums } from "core/enums";
import appEventInstance from "util/eventHandler";
import { AppEventTypes } from "core/enums";
import { FTUData, MyTeamsResponse, AllBadgesList } from "core/interfaces/home";
import { BoosterListResponse, PlayerListResponse, ExtendedUserPlayerCard } from "core/interfaces/team";
import { UserPlayerCard, Odds, UserBets, OddsDisplayEnum, AllTypeStoreItem, PlaceBetMutation } from "generated/graphql";
import getConfig from "util/configHelper";
import gameJson from "config/gameJSON.json";

const { configData } = getConfig();

const {
  finalResult,
  goalsOverUnder,
  bothTeamsToScore,
  resultBothTeamsScore,
  correctScore,
  firstLastAnytimeGoalScorer,
  handicaps,
  firstGoalScorerCorrectScore,
} = gameJson.gamePannelOptionsHeading;

interface DrawerStates {
  isOpenSettingsDrawer: boolean;
  isOpenTeamEditDrawer: boolean;
  isDrawerLoading: boolean;
  isOpenStakePannelDrawer: boolean;
  isOpenStakePannelOptions: boolean;
}

interface ModalStates {
  isOpenGameRulesModal: boolean;
  isOpenStakeInfoModal: boolean;
  isOpenMinMaxStakeModal: boolean;
}

interface StakeOpenPannelState {
  isOpenStakeInputsPannel: boolean;
}

interface UserDetails {
  name: string;
  country: string;
}

interface SelectedStakeOptions {
  id?: string;
  activeStakeInputId?: string;
  active?: boolean;
  hideTitle?: boolean;
  label?: string;
  colSubLabel?: string | null;
  boxSubLabel?: string | null;
  name?: string;
  team?: string | null;
  type?: string;
  value?: string;
  stake?: number | string | any;
}

interface OddsList {
  [key: string]: UserBets[];
}

interface GameStoreState {
  isInitDataAvailable: boolean;
  setIsInitDataAvailable: (value: boolean) => void;
  hideReactPage: { isHide: boolean; hideFor: string };
  setHideReactPage: (value: { isHide: boolean; hideFor: string }) => void;
  matchStatus: string;
  setMatchStatus: (status: string) => void;
  selectedOddsDisplay: string;
  setSelectedOddsDisplay: (value: string) => void;
  showLoader: { isTrue: boolean; loaderFor: string };
  setShowLoader: (value: { isTrue: boolean; loaderFor: string }) => void;
  canvasReady: boolean;
  toggleCanvasReady: (value: boolean) => void;
  selectedFooterMenuId: number;
  setSelectedFooterMenuId: (id: number) => void;
  // drawerDimension: DrawerDimension;
  // updateDrawerDimension: (data: any) => void;
  drawerStates: DrawerStates;
  updateDrawerStates: (data: Partial<DrawerStates>) => void;
  modalStates: ModalStates;
  updateModalStates: (data: Partial<ModalStates>) => void;
  stakeInputPannelState: StakeOpenPannelState;
  updateStakeInputPannelState: (data: Partial<StakeOpenPannelState>) => void;
  boosterDrawerSize: DrawerSizes;
  setBoosterDrawerSize: (size: DrawerSizes) => void;
  boosterAdded: any;
  setBoosterAdded: (data: any) => void;
  closeAllDrawers: () => void;
  myTeamResponse: MyTeamsResponse;
  setMyTeamResponse: (data: Partial<MyTeamsResponse>) => void;
  updateTeamName: (data: { name: string }) => void;
  allBoosterList: BoosterListResponse;
  setAllBoosterList: (data: BoosterListResponse) => void;
  allPlayerList: PlayerListResponse;
  setAllPlayerList: (data: PlayerListResponse) => void;
  selectedSwapPlayer: ExtendedUserPlayerCard | null;
  setSelectedSwapPlayer: (data: ExtendedUserPlayerCard) => void;
  selectedBoosterPlayer: UserPlayerCard | null;
  setSelectedBoosterPlayer: (data: UserPlayerCard) => void;
  allSwapPlayerList: UserPlayerCard[] | [];
  setAllSwapPlayerList: (data: UserPlayerCard[]) => void;
  updateStakeById: (key: string, id: string, newStake: string) => void;
  resetSelectedOddsOptions: () => void;
  stakeOptions: Odds | null;
  setStakeOptions: (data: Odds) => void;
  selectedStakeOptions: SelectedStakeOptions[] | [];
  setSelectedStakeOptions: (newOption: SelectedStakeOptions) => void | [];
  selectedOddsOptions: OddsList; // added to odd but not added stake list in current round
  setSelectedOddsOptions: (data: UserBets[], keyName: string) => void;
  activeOddsOptions: OddsList; // added stake and in the current round stake list
  setActiveOddsOptions: (data: UserBets[]) => void;
  addOddsSlip: (marketName: string, bet: UserBets) => void;
  removeOddsSlip: (marketName: string, betId: string) => void;
  loading: Boolean;
  setLoading: (isLoading: boolean) => void;
  initialUserDetails: UserDetails;
  setInitialUserDetails: (userDetails: Partial<UserDetails>) => void;
  ftuData: FTUData;
  setFtuData: (data: Partial<FTUData>) => void;
  iframeRef: HTMLIFrameElement | null;
  setIframeRef: (ref: HTMLIFrameElement) => void;
  betPlaceData: PlaceBetMutation | null;
  setBetPlaceData: (data: any) => void;
  selectedStoreCardData: any;
  setSelectedStoreCardData: (data: any) => void;
  storeItems: AllTypeStoreItem;
  setStoreItems: (items: AllTypeStoreItem) => void;
  allBadgesList: AllBadgesList;
  setAllBadgesList: (items: AllBadgesList) => void;
}

const useGameStore = create<GameStoreState>((set) => ({
  matchStatus: matchStatusEnums.selectingOdds,
  setMatchStatus: (status: string) => set({ matchStatus: status }),

  selectedStoreCardData: null,
  setSelectedStoreCardData: (data: any) => set({ selectedStoreCardData: data }),

  selectedOddsDisplay: OddsDisplayEnum.Fractional,
  setSelectedOddsDisplay: (value: string) => set({ selectedOddsDisplay: value }),

  betPlaceData: null,
  setBetPlaceData: (data: any) => set({ betPlaceData: data }),

  iframeRef: null, // initial value
  setIframeRef: (ref: HTMLIFrameElement) => set({ iframeRef: ref }),

  loading: false,
  setLoading: (isLoading: boolean) => set({ loading: isLoading }),

  initialUserDetails: { name: "", country: "" },
  setInitialUserDetails: (userDetails: Partial<{ name: string; country: string }>) =>
    set((state) => ({
      initialUserDetails: { ...state.initialUserDetails, ...userDetails },
    })),

  ftuData: null,
  setFtuData: (data: any) => set((state) => ({ ftuData: { ...state.ftuData, ...data } })),

  myTeamResponse: null,
  setMyTeamResponse: (data: Partial<MyTeamsResponse>) =>
    set((state) => {
      return { myTeamResponse: { ...state.myTeamResponse, ...data } as MyTeamsResponse };
    }),

  updateTeamName: (data: { name: string }) => {
    set((state) => ({
      myTeamResponse: {
        ...state.myTeamResponse,
        name: data.name,
      } as MyTeamsResponse,
    }));
  },

  allBadgesList: null,
  setAllBadgesList: (data: AllBadgesList) => set({ allBadgesList: data }),

  allBoosterList: null,
  setAllBoosterList: (data: BoosterListResponse) => set({ allBoosterList: data }),

  allPlayerList: null,
  setAllPlayerList: (data: PlayerListResponse) => set({ allPlayerList: data }),

  selectedSwapPlayer: null,
  setSelectedSwapPlayer: (data: ExtendedUserPlayerCard) => set({ selectedSwapPlayer: data }),

  selectedBoosterPlayer: null,
  setSelectedBoosterPlayer: (data: UserPlayerCard) => set({ selectedBoosterPlayer: data }),

  allSwapPlayerList: [],
  setAllSwapPlayerList: (data: UserPlayerCard[]) => set({ allSwapPlayerList: data }),

  stakeOptions: null,
  setStakeOptions: (data: Odds) => set({ stakeOptions: data }),

  storeItems: {} as AllTypeStoreItem,
  setStoreItems: (items) => set({ storeItems: items }),

  activeOddsOptions: {
    [finalResult]: [],
    [goalsOverUnder]: [],
    [bothTeamsToScore]: [],
    [resultBothTeamsScore]: [],
    [correctScore]: [],
    [firstLastAnytimeGoalScorer]: [],
    [handicaps]: [],
    [firstGoalScorerCorrectScore]: [],
  } as OddsList,
  setActiveOddsOptions: (data: UserBets[]) =>
    set((state) => ({
      activeOddsOptions: data.reduce<Record<string, (typeof data)[number][]>>((acc, bet) => {
        if (bet.betStatus === configData.addedToSlip) {
          return acc; // Skip items with betStatus === "addedToSlip"
        }
        let marketName = bet?.marketName;
        if (
          marketName === configData.firstGoalScorer ||
          marketName === configData.lastGoalScorer ||
          marketName === configData.AnyTimeGoalScorer
        ) {
          marketName = configData.firstLastAnytimeGoalScorer;
        }

        if (!acc[marketName]) {
          acc[marketName] = [];
        }
        acc[marketName].push(bet);

        return acc;
      }, {}),
    })),

  selectedOddsOptions: {
    [finalResult]: [],
    [goalsOverUnder]: [],
    [bothTeamsToScore]: [],
    [resultBothTeamsScore]: [],
    [correctScore]: [],
    [firstLastAnytimeGoalScorer]: [],
    [handicaps]: [],
    [firstGoalScorerCorrectScore]: [],
  } as OddsList,

  setSelectedOddsOptions: (data: UserBets[], keyName: string) =>
    set((state) => ({
      selectedOddsOptions:
        keyName === "reset"
          ? // If keyName is "reset", filter out items with betStatus === "ADDED_TO_SLIP"
            data.reduce<Record<string, (typeof data)[number][]>>((acc, bet) => {
              if (bet.betStatus === configData.debitSuccess) {
                return acc; // Skip items with betStatus === "ADDED_TO_SLIP"
              }

              let marketName = bet?.marketName;

              // Adjust marketName based on the config data
              if (
                marketName === configData.firstGoalScorer ||
                marketName === configData.lastGoalScorer ||
                marketName === configData.AnyTimeGoalScorer
              ) {
                marketName = configData.firstLastAnytimeGoalScorer;
              }

              if (!acc[marketName]) {
                acc[marketName] = [];
              }
              acc[marketName].push(bet);

              return acc;
            }, {})
          : keyName === "removeAllData"
            ? // If keyName is "removeAllData", reset the entire state
              {
                [finalResult]: [],
                [goalsOverUnder]: [],
                [bothTeamsToScore]: [],
                [resultBothTeamsScore]: [],
                [correctScore]: [],
                [firstLastAnytimeGoalScorer]: [],
                [handicaps]: [],
                [firstGoalScorerCorrectScore]: [],
              }
            : {
                // Else, update selectedOddsOptions for the given keyName
                ...state.selectedOddsOptions,
                [keyName]: [...data],
              },
    })),

  //TODO: why updating same state with different call back @Akhil?
  addOddsSlip: (marketName, bet) =>
    set((state) => ({
      selectedOddsOptions: {
        ...state.selectedOddsOptions,
        [marketName]: [...(state.selectedOddsOptions[marketName] || []), bet],
      },
    })),

  removeOddsSlip: (marketName, betId) =>
    set((state) => ({
      selectedOddsOptions: {
        ...state.selectedOddsOptions,
        [marketName]: state.selectedOddsOptions[marketName]?.filter((bet) => bet.betId !== betId),
      },
    })),
  selectedStakeOptions: [],
  setSelectedStakeOptions: (newOption) =>
    set((state) => {
      const exists = state.selectedStakeOptions.some(
        (option) =>
          option.name === newOption.name && option.value === newOption.value && option.team === newOption.team,
      );

      // Otherwise, we add/remove the option based on the existence check
      return {
        selectedStakeOptions: exists
          ? state.selectedStakeOptions.filter(
              (option) =>
                option.name !== newOption.name || option.value !== newOption.value || option.team !== newOption.team,
            ) // Only remove the specific option
          : [...state.selectedStakeOptions, newOption], // Add the new option
      };
    }),
  resetSelectedOddsOptions: () => set(() => ({ selectedStakeOptions: [] })), // Reset to empty array

  updateStakeById: (key: string, id: string, newStake: string) =>
    //@ts-ignore
    set((state) => ({
      selectedOddsOptions: {
        ...state.selectedOddsOptions,
        [key]: state.selectedOddsOptions[key].map((option) =>
          option.oddId === id
            ? { ...option, betAmount: newStake } // No parsing here, keep as a string
            : option,
        ),
      },
    })),

  boosterAdded: {},
  setBoosterAdded: (data: any) => set({ boosterAdded: data }),
  showLoader: { isTrue: false, loaderFor: "" },
  setShowLoader: (value: { isTrue: boolean; loaderFor: string }) => set({ showLoader: value }),
  hideReactPage: { isHide: false, hideFor: "" },
  setHideReactPage: (value: { isHide: boolean; hideFor: string }) => set({ hideReactPage: value }),
  canvasReady: false,
  toggleCanvasReady: (value: boolean) => set({ canvasReady: value }),
  isInitDataAvailable: false,
  setIsInitDataAvailable: (value: boolean) => set({ isInitDataAvailable: value }),
  selectedFooterMenuId: 3,
  setSelectedFooterMenuId: (id: number) => set({ selectedFooterMenuId: id }),

  boosterDrawerSize: DrawerSizes.Lg,
  setBoosterDrawerSize: (size: DrawerSizes) => set({ boosterDrawerSize: size }),

  drawerStates: {
    isOpenSettingsDrawer: false,
    isOpenTeamEditDrawer: false,
    isDrawerLoading: false,
    isOpenStakePannelDrawer: false,
    isOpenStakePannelOptions: false,
  },

  updateDrawerStates: (data: Partial<DrawerStates>) => {
    set((state) => {
      const updatedStates = { ...state.drawerStates, ...data };

      // Dispatch an event to control background scrolling based on whether any drawer is open
      const anyDrawerOpen = updatedStates.isOpenStakePannelDrawer ? false : Object.values(updatedStates).some(Boolean);

      appEventInstance.dispatch({
        eventType: AppEventTypes.ToggleDrawerScroll,
        payload: { isScrollDisabled: anyDrawerOpen },
      });

      return { drawerStates: updatedStates };
    });
  },

  modalStates: {
    isOpenGameRulesModal: false,
    isOpenStakeInfoModal: false,
    isOpenMinMaxStakeModal: false,
  },

  updateModalStates: (data: Partial<ModalStates>) => {
    set((state) => ({
      modalStates: { ...state.modalStates, ...data },
    }));
  },

  // Function to close all drawers on component unmount
  closeAllDrawers: () => {
    set((state) => {
      const allDrawersClosed = Object.values(state.drawerStates).every((isOpen) => !isOpen);

      // If any drawer is open, close them all
      if (!allDrawersClosed) {
        const closedDrawerStates = Object.keys(state.drawerStates).reduce((acc, key) => {
          acc[key as keyof DrawerStates] = false;
          return acc;
        }, {} as DrawerStates);

        appEventInstance.dispatch({
          eventType: AppEventTypes.ToggleDrawerScroll,
          payload: { isScrollDisabled: false },
        });

        return { drawerStates: closedDrawerStates };
      }

      return state;
    });
  },

  stakeInputPannelState: {
    isOpenStakeInputsPannel: false,
  },

  updateStakeInputPannelState: (data: Partial<StakeOpenPannelState>) => {
    set((state) => ({
      stakeInputPannelState: { ...state.stakeInputPannelState, ...data },
    }));
  },
}));

export default useGameStore;
