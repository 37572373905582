import React, { useState } from "react";
import Typography from "components/Typography";
import classNames from "classnames";
import styles from "styles/pages/stakePannelOptions/components/firstGoalScorer.module.css";
import { CardRarityEnum, OddsDisplayEnum } from "generated/graphql";
import Accordion from "components/Accordion";
import BoxSelection from "components/BoxSelection";
import sessionHelper from "service/sessionHelper";
import { debug } from "util/miscUtils";
import useHandleAddUpdateOdds from "hooks/useHandleAddUpdateOdds";
import useGameStore from "store/gameStore";
import { shallow } from "zustand/shallow";
import { matchStatusEnums } from "core/enums";

type ScoreState = {
  teamA: number;
  teamB: number;
};

interface PlayerOdds {
  id: string;
  playerId: string;
  teamId: string;
  team1: number;
  team2: number;
  odd: string;
  decimalOdd: number;
  xpBoostPercentage: number;
  fractionalOdd?: string;
  americanOdd?: string;
}

const FirstGoalscorer = () => {
  let firstGoalScorerOddsDetails = sessionHelper.oddsDetails?.firstGoalscorer;
  let keyName = firstGoalScorerOddsDetails?.name as string;
  debug({ event: "calling", message: "first goal scorer" });
  const selectedFirstGoalScorerOptions = useGameStore((state) => state.selectedOddsOptions[keyName] || [], shallow);

  const { setSelectedOddsOptions, selectedOddsDisplay, matchStatus } = useGameStore(
    (state) => ({
      setSelectedOddsOptions: state.setSelectedOddsOptions,
      selectedOddsDisplay: state.selectedOddsDisplay,
      matchStatus: state.matchStatus,
    }),
    shallow,
  );
  const [playersList, setPlayersList] = useState<PlayerOdds[]>([]); // initially showing all players list ...
  const [openContent, setOpenContent] = useState(true);
  const [loadingIds, setLoadingIds] = useState(new Set()); // Tracks loading state by item ID

  const [score, setScore] = useState<ScoreState>({
    teamA: 0,
    teamB: 0,
  });
  let totalTeamScore = score.teamA + score.teamB;
  // console.log(sessionHelper.oddsDetails?.firstGoalscorer?.data);
  const [showMore, setShowMore] = useState(false); // State to control Show More / Show Less
  const maxVisibleItems = 4; //  how many items to show initially

  const filteredData = sessionHelper?.oddsDetails?.firstGoalscorer?.data[`${score.teamA}-${score.teamB}`] || [];

  const renderData = showMore ? filteredData : filteredData.slice(0, maxVisibleItems);

  const updateScore = (team: string, increment: number) => {
    if (score.teamA + score.teamB === 6 && increment === 1) {
      return;
    }
    let _score = {
      ...score,
      //@ts-ignore
      [team]: Math.max(0, Math.min(6, score[team] + increment)),
    };
    setScore({ ..._score });
    let updatedPlayerList = sessionHelper.oddsDetails?.firstGoalscorer.data[`${_score.teamA}-${_score.teamB}`];
    setPlayersList(updatedPlayerList);
  };

  const getTeamName = (name: string) => {
    if (name === "A") return sessionHelper.oddsDetails?.teamA.name || "-";
    if (name === "B") return sessionHelper.oddsDetails?.teamB.name || "-";
    return "Draw";
  };

  let firstGoalScorerOdds = sessionHelper.oddsDetails?.firstGoalscorer;
  const { handleBoxClick, isAddingBetLoading, error } = useHandleAddUpdateOdds();

  const renderPlayer = (id: string) => {
    let fetchPlayerDetails = sessionHelper.oddsDetails?.allPlayers.find((el) => el.playerId === id);

    if (!fetchPlayerDetails) {
      return <Typography size="xs">{sessionHelper?.content.notFound}</Typography>;
    }
    const { teamId, name, attackRating, rarity, currentPosition, preferredPosition } = fetchPlayerDetails;

    return (
      <section
        className={classNames(styles.playerDetails, {
          [styles.hide]: currentPosition === "GK",
        })}
      >
        <div
          className={classNames(styles.attRating, {
            [styles.bgBronze]: rarity === CardRarityEnum.Bronze,
            [styles.bgSilver]: rarity === CardRarityEnum.Silver,
            [styles.bgGold]: rarity === CardRarityEnum.Gold,
            [styles.bgInForm]: rarity === CardRarityEnum.InForm,
          })}
        >
          <Typography size="xs3" color={"black"} fontFamily="avenirFont">
            {sessionHelper?.content.ATT}
          </Typography>
          <Typography size="md" color="black" fontFamily="avenirNextBold">
            {attackRating}
          </Typography>
        </div>
        <div className={styles.teamDetails}>
          <Typography color="grayPlus" size="md" fontFamily="avenirFont">
            {name}
          </Typography>{" "}
          <Typography color="grayPlus" size="xs" fontFamily="avenirFont">
            {getTeamName(teamId as string)}
          </Typography>
        </div>
      </section>
    );
  };

  const handleUserInteraction = async (id: string, keyName: string) => {
    if (matchStatus === matchStatusEnums.betPlaced) return;
    if (!id) return;
    try {
      setLoadingIds((prev) => new Set(prev).add(id)); // Mark the specific box as loading
      let resp = await handleBoxClick(id, keyName, [...selectedFirstGoalScorerOptions]);
      setSelectedOddsOptions(resp || [], keyName);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingIds((prev) => {
        const updated = new Set(prev);
        updated.delete(id); // Remove loading state for the box
        return updated;
      });
    }
  };

  //  check selected Market playerList oddId with the SelectedFirstGoalScorerOptions list
  const isCheckActive = (id: any) => {
    let x = selectedFirstGoalScorerOptions.find((data) => data.oddId === id);
    return x?.betId ? true : false;
  };
  // console.log(score, sessionHelper?.oddsDetails?.firstGoalscorer?.data[`${score.teamA}-${score.teamB}`]);

  return (
    <section className={styles.root}>
      <Accordion
        open={openContent}
        showAll={showMore}
        boxHeading={firstGoalScorerOdds?.name || "-"}
        handleHeaderClick={() => setOpenContent(!openContent)}
        type="settings"
      >
        <div className={styles.colRowContainer}>
          <div className={styles.row}>
            <div className={classNames(styles.col)}>
              <Typography color="grayPlus" size={"md"} fontFamily="avenirFont">
                {" "}
                {sessionHelper.oddsDetails?._id &&
                  sessionHelper.oddsDetails?.teamA.name &&
                  sessionHelper.oddsDetails?.teamA.name}
              </Typography>
            </div>
            <div className={styles.col}>
              <Typography color="grayPlus" size={"md"} fontFamily="avenirFont">
                {sessionHelper.oddsDetails?._id &&
                  sessionHelper.oddsDetails?.teamB.name &&
                  sessionHelper.oddsDetails?.teamB.name}
              </Typography>
            </div>
          </div>
          <div className={styles.row}>
            {Object.entries(score).map(([team, value], index) => (
              <div key={index} className={styles.col}>
                <div
                  className={classNames(styles.plusMinusBox, styles.paddingSm)}
                  onClick={() => updateScore(team, -1)}
                  style={{ cursor: "pointer" }}
                >
                  <img src="icon/minus.png" alt="minusIcon" />
                </div>
                <Typography size="xl" fontFamily="avenirNextSemiBold">
                  {value}
                </Typography>
                <div
                  className={classNames(styles.plusMinusBox, styles.paddingSm)}
                  onClick={() => updateScore(team, 1)}
                  style={{ cursor: "pointer" }}
                >
                  <img src={`icon/${score.teamA + score.teamB === 6 ? "plusGray" : "plus"}.png`} alt="plusIcon" />
                </div>
              </div>
            ))}
          </div>
          {renderData.map((oddDetails: any, oddIndex: any) => {
            if (oddDetails.currentPosition === "GK") return null; // Skip GK players

            const playerData = playersList?.find((data) => data.playerId === oddDetails.playerId);

            return (
              <div key={`${oddIndex}-${oddDetails.teamId}`} className={styles.row}>
                <div className={styles.col}>{renderPlayer(oddDetails.playerId)}</div>
                <div className={styles.boxWidth}>
                  <BoxSelection
                    active={isCheckActive(playerData?.id)}
                    onClick={() => handleUserInteraction(playerData?.id as string, keyName)}
                    value={
                      selectedOddsDisplay
                        ? selectedOddsDisplay === OddsDisplayEnum.Fractional
                          ? oddDetails?.fractionalOdd
                          : selectedOddsDisplay === OddsDisplayEnum.Decimal
                            ? oddDetails?.decimalOdd
                            : oddDetails?.americanOdd
                        : ""
                    }
                    numberSelectorForTextSize={5}
                    fixedHeight={true}
                    isClicked={loadingIds.has(playerData?.id)} // Check if this box is in a loading state
                  />
                </div>
              </div>
            );
          })}
        </div>

        {filteredData.length > maxVisibleItems && (
          <div onClick={() => setShowMore((prev) => !prev)} className={styles.showMoreContainer}>
            <Typography size="lg" className={styles.showMoreButton}>
              {showMore ? sessionHelper?.content.showLess : sessionHelper?.content.showMore}
            </Typography>
            <img
              className={classNames(styles.arrowIcon, {
                [styles.rotateIcon]: showMore,
              })}
              src={"icon/arrowDown.png"}
              alt=""
            />
          </div>
        )}
      </Accordion>
    </section>
  );
};

export default React.memo(FirstGoalscorer);
