import config from "config/config.json";
import errConfig from "config/err.json";
import enConfig from "config/en/config.json";
import enErrMess from "config/en/err.json";
import sessionHelper from "service/sessionHelper";

const getConfig = (lang = null) => {
  let configData = { ...config };
  let errConfigData = { ...errConfig }; // Use this instead of directly reassigning errConfig

  switch (lang || sessionHelper?.language) {
    case "en":
      configData = { ...configData, ...enConfig };
      errConfigData = { ...errConfigData, ...enErrMess }; // Update errConfigData, not errConfig
      break;
    default:
  }

  return { configData, errConfigData };
};

export default getConfig;
