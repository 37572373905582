import React from "react";
import { shallow } from "zustand/shallow";
import classNames from "classnames";
import Typography from "components/Typography";
import Modal from "components/Modal";
import useGameStore from "store/gameStore";
import styles from "styles/components/modals/minMaxStakeModal.module.css";
import gameJson from "config/gameJSON.json";
import { getConfigLabel, parseMessage } from "util/miscUtils";

const MinMaxStakeModal = () => {
  const { isOpenMinMaxStakeModal, updateModalStates } = useGameStore(
    (state) => ({
      isOpenMinMaxStakeModal: state.modalStates.isOpenMinMaxStakeModal,
      updateModalStates: state.updateModalStates,
    }),
    shallow,
  );

  const handleModalClose = () => {
    updateModalStates({ isOpenMinMaxStakeModal: false });
  };

  const stake = ["£0.10", "£10,000.00", "£250,000.00"];
  return (
    <Modal id="minMaxStakeModal" open={isOpenMinMaxStakeModal} handleClose={handleModalClose} containerSize="lg">
      <div className={classNames(styles.body)}>
        {/* <img src="icon/errorPopUp.png" alt="errorPopUp" className="w-12" /> */}
        <div className={classNames(styles.text)}>
          {gameJson.minMaxValueList.map((item, index) => (
            <div key={`${item.id} - ${index}`}>
              <li>{parseMessage({ str: getConfigLabel(item.label), map: [stake[index]] })}</li>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
export default React.memo(MinMaxStakeModal);
