import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "styles/components/accordion.module.css";
import Typography from "components/Typography";

type AccordionProps = {
  open: boolean;
  showAll?: boolean;
  boxHeading: string;
  handleHeaderClick: () => void;
  type?: string;
  icon?: string;
  children: React.ReactNode;
};

const Accordion = ({
  open,
  showAll,
  boxHeading,
  handleHeaderClick,
  children,
  type,
  icon = "icon/arrowDown.png",
}: AccordionProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false); // State to track initial load

  // Set the isLoaded state to true after the first render
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded && contentRef.current) {
      const content = contentRef.current;
      // Use requestAnimationFrame to ensure DOM updates are complete
      requestAnimationFrame(() => {
        if (open) {
          content.style.maxHeight = `${content.scrollHeight}px`; // Expand to full height
        } else {
          content.style.maxHeight = "0"; // Collapse to zero height
        }
      });
    }
  }, [open, showAll, isLoaded]); // Trigger on open state and after initial load

  return (
    <section className={styles.root}>
      <header onClick={handleHeaderClick} className={styles.accordionHeader}>
        <Typography size="md" fontFamily="avenirNextSemiBold">
          {boxHeading}
        </Typography>
        <img
          src={icon}
          className={classNames(styles.accordionIcon, {
            [styles.rotateAccordionIcon]: open,
          })}
          alt="arrowIcon"
        />
      </header>
      <div
        ref={isLoaded ? contentRef : null} // Apply ref only after the initial render
        className={classNames(styles.childRoot, { [styles.open]: open })}
        style={{ maxHeight: `${contentRef.current?.style.maxHeight || "0"}px` }}
      >
        {children}
      </div>
    </section>
  );
};

export default React.memo(Accordion);
