import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Modal from "components/Modal";
import Typography from "components/Typography";
import appEventInstance from "util/eventHandler";
import { AppEventTypes } from "core/enums";
import Button from "components/Button";
import styles from "styles/components/modals/errorPopUpModal.module.css";
import getConfig from "util/configHelper";
import { timer } from "util/miscUtils";

const ErrorPopUpModal = () => {
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSuccess, setIssuccess] = useState(false);
  const { configData, errConfigData } = getConfig();

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.toggleErrorModal, (info) => {
      const { detail } = info;

      setMsg(detail.msg);
      setIssuccess(detail.success || false);
      setOpen(true);
    });
    return () => {
      appEventInstance.remove(AppEventTypes.toggleErrorModal);
    };
  }, []);

  const handleClose = async () => {
    if (msg === errConfigData.GEN01) {
      window.location.reload();
    }

    setOpen(false);
    let _modal = document.getElementById(AppEventTypes.toggleErrorModal);
    console.log(_modal);
    setTimeout(() => {
      _modal?.classList.add(styles.hide);
    }, 500);
    await timer(200);
    setMsg("");
  };

  return (
    <Modal
      id={`${AppEventTypes.toggleErrorModal}`}
      open={open}
      handleClose={handleClose}
      title=""
      containerSize={isSuccess ? "lg" : "xs"}
      disableOverlayInteraction={true}
    >
      <div
        className={classNames(styles.body, {
          [styles.addSuccessGap]: isSuccess,
        })}
      >
        <img
          src="icon/errorPopUp.png"
          alt="errorPopUp"
          className={classNames(styles.icon, {
            [styles.hide]: isSuccess,
          })}
        />
        <div className={classNames(styles.errormsg)}>
          <Typography fontFamily="avenirNextSemiBold">{msg}</Typography>
        </div>
        <div className={classNames(styles.btn)}>
          <Button color="blueBtn" size="small" onClick={handleClose}>
            <Typography fontFamily="degularBlack" color="navyBlue">
              OK
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ErrorPopUpModal);
