import React, { useEffect, useState } from "react";

const NumberCounter = ({ from, to }: { from: number; to: number }) => {
  const [count, setCount] = useState(from);
  const duration = 700; // Faster animation
  const steps = Math.abs(to - from) || 1;
  const intervalTime = Math.max(duration / steps, 4); // Ensure at least 4ms interval

  useEffect(() => {
    setCount(from); // Reset count to 'from' when props change

    let interval: NodeJS.Timeout | null = null;

    if (from !== to) {
      interval = setInterval(() => {
        setCount((prev) => {
          if ((from < to && prev >= to) || (from > to && prev <= to)) {
            clearInterval(interval!);
            return to;
          }
          return from < to ? prev + Math.ceil(steps / (duration / 4)) : prev - Math.ceil(steps / (duration / 4));
        });
      }, intervalTime);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [from, to]); // Ensure animation restarts when values change

  return <>{count}</>;
};

export default NumberCounter;
