import React from "react";
import classNames from "classnames";
import Typography from "components/Typography";
import { TeamData } from "core/interfaces/common";
import styles from "styles/pages/teamSelect/components/statsCard.module.css";

interface StatsCardProps {
  team: "opponent" | "myTeam";
  tableTitle: string;
  roundedCorner: Boolean | false;
  bgColor?: String;
  data: TeamData;
}

const StatsCard: React.FC<StatsCardProps> = ({ team = "myteam", data, bgColor }) => {
  if (!data) {
    return <Typography>no Data Avaiblae</Typography>;
  }

  const { attackRating, defenseRating, creativityRating, overallRating, kitColor } = data;

  const team1 = [
    { value: attackRating, label: "Attack" },
    { value: defenseRating, label: "Defence" },
    { value: creativityRating, label: "Creativity" },
    { value: overallRating, label: "OVR" },
  ];

  const team2 = [
    { value: creativityRating, label: "Creativity" },
    { value: defenseRating, label: "Defence" },
    { value: attackRating, label: "Attack" },
    { value: overallRating, label: "OVR" },
  ];

  return (
    <section
      style={{ background: `#${kitColor}` }}
      className={classNames(styles.statsCardRoot, {
        [styles.oppBg]: team === "opponent",
      })}
    >
      <div
        className={classNames(styles.row, {
          [styles.oppoTeamDetails]: team === "opponent",
        })}
      >
        {(team === "opponent" ? team2 : team1).map((data, index) => {
          return (
            <React.Fragment key={index}>
              <div
                key={`${index}-data`}
                className={classNames(styles.col, {
                  [styles.oppoTeamDetails]: team === "opponent",
                })}
              >
                <Typography color="gray" size="xs3" fontFamily="avenirNextMedium">
                  {data.label}
                </Typography>
                <Typography
                  fontFamily={"avenirNextBold"}
                  size={data.label === "OVR" ? "2xl" : "lg"}
                  hide={data.label === "OVR"}
                >
                  {data.value}
                </Typography>
                <Typography
                  fontFamily={"avenirNextBold"}
                  size={data.label === "OVR" ? "2xl" : "lg"}
                  hide={data.label !== "OVR"}
                >
                  {data.value}
                </Typography>
              </div>
              <div
                className={classNames(styles.divider, {
                  [styles.hide]: index >= team1?.length - 2,
                })}
              ></div>
            </React.Fragment>
          );
        })}
      </div>
    </section>
  );
};

export default StatsCard;
