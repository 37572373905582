import { AppEventTypes, CanvasEvents, CanvasMessage, loaderTypes } from "core/enums";
import ContentShell from "components/ContentShell";
import PixiContainer from "components/PixiContainer";
import Bets from "pages/bets/Bets";
import { useMeLazyQuery, useOddsLazyQuery, useAllBadgesLazyQuery } from "generated/graphql";
import React, { useEffect } from "react";
import useGameStore from "store/gameStore";
import sessionHelper from "service/sessionHelper";
import appEventInstance from "util/eventHandler";
import styles from "styles/pages/home/home.module.css";
import classNames from "classnames";
import getConfig from "util/configHelper";
import { shallow } from "zustand/shallow";
import errConfig from "core/err";
import { timer } from "util/miscUtils";
import i18n from "i18n";

const Home = () => {
  const { configData, errConfigData } = getConfig();
  const {
    updateDrawerStates,
    setMyTeamResponse,
    updateModalStates,
    setIframeRef,
    setSelectedOddsDisplay,
    setShowLoader,
    isOpenSettingsDrawer,
    setHideReactPage,
    setIsInitDataAvailable,
    setAllBadgesList,
  } = useGameStore(
    (state) => ({
      updateDrawerStates: state.updateDrawerStates,
      setShowLoader: state.setShowLoader,
      updateModalStates: state.updateModalStates,
      betPlaceData: state.betPlaceData,
      setMyTeamResponse: state.setMyTeamResponse,
      isOpenSettingsDrawer: state.drawerStates.isOpenSettingsDrawer,
      setIframeRef: state.setIframeRef,
      setSelectedOddsDisplay: state.setSelectedOddsDisplay,
      myTeamResponse: state.myTeamResponse,
      hideReactPage: state.hideReactPage,
      setHideReactPage: state.setHideReactPage,
      setIsInitDataAvailable: state.setIsInitDataAvailable,
      setAllBadgesList: state.setAllBadgesList,
    }),
    shallow,
  );

  const teamDataRef = React.useRef({});
  const paddingRef = React.useRef({ top: 74, bottom: 79 });
  const [dimension, setDimension] = React.useState({ top: 74, bottom: 79 });
  const [page, setPage] = React.useState(CanvasEvents.home);
  const iframe = React.useRef(null);
  const [fetchUserDetails] = useMeLazyQuery();
  const [fetchStakeOptionsOddsList] = useOddsLazyQuery();

  const _transferInfoToPixi = async (data: { type: string; value: any }) => {
    const iframeRef = useGameStore.getState().iframeRef;
    //@ts-ignore
    console.log("wrapper: _transferInfoToPixi", data, iframeRef, iframeRef?.contentWindow?.gameExtension);
    if (
      iframeRef &&
      //@ts-ignore
      iframeRef?.contentWindow
    ) {
      console.log("wrapper: _transferInfoToPixi to be send");
      //@ts-ignore
      iframeRef?.contentWindow?.gameExtension(data);
    }
  };

  const [fetchAllBadges] = useAllBadgesLazyQuery({
    onCompleted: (data) => {
      setAllBadgesList(data?.teamManager?.allBadges);
    },
  });

  const _getCurrStateValues = () => {
    let myTeamResponse = useGameStore.getState().myTeamResponse;
    let allBadgesResponse = useGameStore.getState().allBadgesList;

    return { myTeamResponse, allBadgesResponse };
  };

  const _handleCanvasEvent = async (info: CustomEvent) => {
    const { detail } = info;

    if (detail?.padding?.top) {
      appEventInstance.dispatch({ eventType: AppEventTypes.toggleDrawerHeight, payload: { ...detail?.padding } });
      if (paddingRef.current.top !== detail?.padding?.top || paddingRef.current.bottom !== detail?.padding?.bottom) {
        console.log("wrapper: page dimension changed", detail?.padding);
        setDimension({ ...detail?.padding });
        paddingRef.current = { ...detail?.padding };
      }
    }
    try {
      switch (detail.messageName) {
        case CanvasEvents.initData:
          console.log({ detailsFromCanvas: detail });

          const { language, translations } = detail;

          // Update i18next with the received translations and language
          i18n.addResources(language, "translation", translations[language]?.translation || {});
          i18n.changeLanguage(language);

          sessionHelper.onSignIn({
            ...detail?.data,
            content: detail?.content,
            language: "en",
            isFTU: false,
          });
          await timer(100);
          setIsInitDataAvailable(true);

          //@ts-ignore
          await _transferInfoToPixi?.({
            type: CanvasMessage.configData,
            value: { ...configData },
          });

          const _userDetailsResp = await fetchUserDetails();
          if (_userDetailsResp?.data?.players?.me?._id) {
            let OddsDisplay = _userDetailsResp?.data?.players?.me?.settings?.oddsDisplay;
            //@ts-ignore
            await _transferInfoToPixi?.({
              type: CanvasMessage.oddsType,
              value: { oddsType: OddsDisplay },
            });
            setSelectedOddsDisplay(OddsDisplay);
          }

          break;
        case CanvasEvents.teamData:
          fetchAllBadges();
          teamDataRef.current = { ...detail?.data };
          setMyTeamResponse(detail?.data);
          break;
        case CanvasEvents.udpateBalance:
          console.log("balance detail from canvas :    ", { detail });
          sessionHelper.onSignIn({
            ...sessionHelper,
            isFTU: false,
            balance: detail.balance,
            pbfCoin: detail.pbfCoin,
          });
          break;
        case CanvasEvents.teamEdit:
          const { myTeamResponse, allBadgesResponse } = _getCurrStateValues();
          teamDataRef.current = { ...myTeamResponse };
          appEventInstance.dispatch({
            eventType: AppEventTypes.populateTeamEdit,
            payload: { data: { ...teamDataRef.current }, badgeList: allBadgesResponse },
          });

          updateDrawerStates({ isOpenTeamEditDrawer: true });
          break;
        case CanvasEvents.showSetting:
          if (!isOpenSettingsDrawer) {
            updateDrawerStates({ isOpenSettingsDrawer: true });
          }
          break;
        case CanvasEvents.showPackSuccess:
          appEventInstance.dispatch({
            eventType: AppEventTypes.toggleErrorModal,
            payload: { msg: errConfig.Succes02, success: true },
          });
          setHideReactPage({ isHide: false, hideFor: "" });
          break;
        case CanvasEvents.showBet:
          setShowLoader({ isTrue: true, loaderFor: loaderTypes.oddsList });
          updateDrawerStates({ isOpenStakePannelOptions: true });
          const _oddsResp = await fetchStakeOptionsOddsList();
          if (_oddsResp?.data?.players?.odds?.matchId) {
            sessionHelper.updateOddDetails(_oddsResp?.data?.players?.odds);
            if (sessionHelper.oddsDetails?.matchId.length) {
              setShowLoader({ isTrue: false, loaderFor: loaderTypes.oddsList });
            }
          } else {
            appEventInstance.dispatch({
              eventType: AppEventTypes.toggleErrorModal,
              payload: { msg: errConfigData.Stake04 },
            });
          }
          break;
        case CanvasEvents.home:
          setPage(detail.messageName);
          break;

        case CanvasEvents.teams:
          setPage(detail.messageName);
          break;
        case CanvasEvents.myBets:
          setShowLoader({ isTrue: true, loaderFor: loaderTypes.betHistory });
          setPage(detail.messageName);
          break;
        case CanvasEvents.hideReactPage:
          setPage(detail.messageName);
          break;
        case CanvasEvents.ftuiComplete:
          sessionHelper.onSignIn({ ...sessionHelper, isFTU: false });
          break;
        case CanvasEvents.wallet:
        default:
      }
    } catch (e) {
      console.log("Home: err", e);
    }
  };

  useEffect(() => {
    appEventInstance.listen(AppEventTypes.canvasEvent, _handleCanvasEvent);

    return () => {
      appEventInstance.remove(AppEventTypes.canvasEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (iframe.current) {
      console.log("wrapper: global ref setuped");
      setIframeRef(iframe.current);
    }
  }, [setIframeRef]);

  return (
    <ContentShell>
      <section className={styles.root}>
        <PixiContainer iframe={iframe} />
        {/* <PixiContainer view={CanvasView.home} /> */}
        {[CanvasEvents.home, CanvasEvents.teams, CanvasEvents.hideReactPage].includes(page) ? null : (
          <div
            id="pageContainer"
            style={{ top: `${dimension?.top || 0}px`, bottom: `${dimension?.bottom || 0}px` }}
            className={classNames(styles.default, {
              [styles.hideEl]: true,
            })}
          >
            {page === CanvasEvents.myBets && <Bets />}
          </div>
        )}
      </section>
    </ContentShell>
  );
};

export default Home;
