import "./App.css";
import { HashRouter } from "react-router-dom";
import Router from "router/Router";
import AppShell from "components/AppShell";
import { ApolloProvider } from "@apollo/client";
import { client } from "apollo";
import SessionHelper from "components/SessionHelper";

function App() {
  return (
    <HashRouter>
        <ApolloProvider client={client}>
          <SessionHelper>
            <AppShell>
              <Router />
            </AppShell>
          </SessionHelper>
        </ApolloProvider>
    </HashRouter>
  );
}

export default App;
